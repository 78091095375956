const Users = [
	{
		email: "plaschema@admin.com",
		password: "plaschema123",
		name: "IDL Admin",
	},
	{
		email: "bigasamaben@yahoo.com",
		password: "asama234",
		name: "Asama Benjamin",
	},

	{
		email: "cgurumyen@gmail.com",
		password: "guru234",
		name: "Cornelius Gurumyen",
	},
	{
		email: "kwandeexcel@gmail.com",
		password: "kwande234",
		name: "Dr. Kwande",
	},
];
export default Users;
