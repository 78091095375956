import React, { useState, useCallback } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DeletePrincipal({ apiToken }) {
	const [user, setUser] = useState([]);
	const [enrollmentId, setEnrollmentId] = useState("");
	const [showEdit, setShowEdit] = useState(false);
	const [buttonText, setButtonText] = useState("Verify");

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	//verify beneficiary
	const getDetails = useCallback(
		async (e) => {
			e.preventDefault();
			setButtonText("Verifying...");
			try {
				const fetchData = await Axios.post(
					`${baseUrl}/verify/enrid/${enrollmentId}`,
				);
				const response = fetchData.data.data;

				if (response) {
					setUser(response);
					setButtonText("Verify");
				} else {
					setUser(null);
					setButtonText("Verify");
					toast.error(
						"Could not verify beneficiary! Please check the enrollment ID and try again",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			} catch (error) {
				setUser(null);
				setButtonText("Verify");
				console.log(error);
				if (error.status === 404) {
					toast.error("Server Error. Please try again shortly.", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});
				} else {
					toast.error("Verification error! Please try again later.", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});
				}
			}
		},
		[enrollmentId, baseUrl],
	);

	const handleDelete = () => {
		const authToken = process.env.REACT_APP_AUTH;

		Axios({
			method: "DELETE",
			url: `${baseUrl}/enrollment/${enrollmentId}`,
			headers: {
				"Content-Type": "application/json",
				Authorization: authToken,
			},
		})
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					toast.success("Principal has been Deleted successfully!", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});
					setShowEdit(false);
				} else {
					toast.error(
						"Authorization failed and Access denied. Try again later!",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			})
			.catch(function (error) {
				console.log(error);
				toast.error(
					"Error encountered or Authorization failed.Try again later!",
					{
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					},
				);
			});
	};
	return (
		<FormWrapper>
			<ToastContainer />

			<Paper className="paper">
				<div>
					<p className="font-pop font-medium text-lg text-white my-2 mx-auto p-3 text-center bg-[#32CD32]">
						Verify Principal
					</p>
					<form onSubmit={getDetails}>
						<div className="flex my-5 items-center justify-around">
							<TextField
								required
								label="Enter Enrollment ID"
								sx={{
									width: "35ch",
								}}
								value={enrollmentId}
								onChange={(e) => setEnrollmentId(e.target.value)}
							/>
							<Button
								type="submit"
								disabled={!enrollmentId ? true : false}
								variant="contained"
								sx={{ ml: 3, width: "20ch", backgroundColor: "#00B4D8" }}
							>
								{buttonText}
							</Button>
						</div>

						<Divider sx={{ mb: 1 }} />
						<p className="font-pop font-medium text-lg text-white my-2 mx-auto p-3 text-center bg-[#32CD32]">
							Principal Details
						</p>

						<TextField
							placeholder="Name"
							InputProps={{
								readOnly: true,
							}}
							sx={{
								width: "40ch",
								mt: 2,
								ml: 10,
							}}
							value={user?.name || ""}
						/>
						<TextField
							placeholder="Subcategory item"
							InputProps={{
								readOnly: true,
							}}
							sx={{
								width: "40ch",
								mt: 2,
								ml: 10,
							}}
							value={user?.subcategoryitem?.name || ""}
						/>
						<TextField
							placeholder="Facility"
							InputProps={{
								readOnly: true,
							}}
							sx={{
								width: "40ch",
								mt: 2,
								ml: 10,
								mb: 2,
							}}
							value={user?.facility?.name || ""}
						/>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								fullWidth
								variant="contained"
								disabled={user?.name ? false : true}
								sx={{
									mt: 2,
									width: "23ch",
									mb: 1,
									backgroundColor: "#00B4D8",
								}}
								onClick={() => setShowEdit(!showEdit)}
							>
								Delete Principal?
							</Button>
						</div>
					</form>
					<Divider sx={{ mb: 1 }} />

					{showEdit && (
						<div className="p-8 my-3 shadow-lg mx-auto">
							<p>
								You are about to delete{" "}
								<abbr className="text-blue-500">{user?.name}</abbr> from the
								system. Are you sure about taking this action?
							</p>
							<div className="mx-auto flex items-center justify-center">
								<Button
									fullWidth
									variant="contained"
									color="error"
									sx={{
										mt: 2,
										width: "23ch",
										mb: 1,
									}}
									onClick={handleDelete}
								>
									Delete
								</Button>
							</div>
						</div>
					)}
				</div>
			</Paper>
		</FormWrapper>
	);
}

export default DeletePrincipal;

const FormWrapper = styled.div`
	margin: 0 3rem 0 17rem;
	.paper {
		padding: 1rem;
		margin-top: -2px;
		margin-left: -7rem;
		display: flex;
		flex-direction: column;
		height: auto;
		width: 40vw;
	}

	.sub-title2 {
		background-color: #006778;
		color: whitesmoke;
		padding: 5px 30px;
		margin-right: 15rem;
	}
	.fac-1 {
		display: flex;
		align-items: center;
	}
`;
