import React, { useState } from "react";
import { Form, Button, DatePicker, Input, Select, Divider } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";

const { Option } = Select;

function Download() {
	const [form] = Form.useForm();
	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL_TWO;

	// ************************************************** Validation
	const isFormComplete = () => {
		return email && selectedCategory;
	};

	// *********************************************HANDLERS
	const handleStartDateChange = (date) => {
		setSelectedStartDate(date);
	};
	const handleEndDateChange = (date) => {
		setSelectedEndDate(date);
	};

	// ********************************************** DOWNLOAD REPORT
	const downloadSectorData = async () => {
		const authToken = process.env.REACT_APP_EVAC_TOKEN;
		setLoading(true);

		const params = JSON.stringify({
			email: email,
			start_date: selectedStartDate
				? selectedStartDate.format("YYYY-MM-DD")
				: null,
			end_date: selectedEndDate ? selectedEndDate.format("YYYY-MM-DD") : null,
		});

		Axios(`${baseUrl}/categories/${selectedCategory}/data`, {
			method: "POST",
			data: params,
			headers: {
				"Content-Type": "application/json",
				Authorization: authToken,
			},
		})
			.then((res) => {
				toast.success(`${res.data.message}`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: false,
				});
				form.resetFields();
			})

			.catch((err) => {
				console.log(err);
				toast.error(`🤦 Something went wrong"`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: false,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className="w-[40vw] mx-auto bg-white my-10 shadow-md">
			<ToastContainer />
			<h2 className="text-2xl text-center text-[#36ca6d] font-semibold tracking-wider py-3">
				Download Sector Data
			</h2>

			<Divider />

			<Form
				layout="horizontal"
				form={form}
				style={{
					maxWidth: 600,
				}}
				className="my-2 p-4 lg:p-8 rounded-md mx-auto font-semibold leading-10"
			>
				<Form.Item
					label="Sector"
					name="sector"
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 16 }}
					rules={[
						{
							required: true,
						},
					]}
				>
					<Select
						value={selectedCategory}
						onChange={(value) => setSelectedCategory(value)}
						allowClear
						style={{
							width: "90%",
						}}
						placeholder="Select"
						className="mb-3"
					>
						<Option value="Formal">Formal</Option>
						<Option value="Informal">Informal</Option>
						<Option value="Equity">Equity</Option>
						<Option value="Not_Merged">Not Merged</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="Email"
					name="email"
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 14 }}
					rules={[
						{
							required: true,
							type: "email",
							message: "Please enter email address",
						},
					]}
				>
					<Input
						className="mb-3"
						name="email"
						value={email}
						placeholder="Please enter email the data will be sent to"
						onChange={(event) => setEmail(event.target.value)}
					/>
				</Form.Item>

				<Form.Item
					label="Start Date"
					name="start_date"
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 16 }}
				>
					<DatePicker
						className="mb-3"
						allowClear
						placeholder="Select a start date"
						onChange={handleStartDateChange}
						style={{
							width: "90%",
						}}
						getPopupContainer={(trigger) => trigger.parentNode}
					/>
				</Form.Item>
				<Form.Item
					label="End Date"
					name="end_date"
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 16 }}
				>
					<DatePicker
						onChange={handleEndDateChange}
						allowClear
						placeholder="Select an end date"
						style={{
							width: "90%",
						}}
						getPopupContainer={(trigger) => trigger.parentNode}
					/>
				</Form.Item>

				<div className="text-center">
					<Button
						type="success"
						loading={loading}
						className="bg-[#36ca6d] text-white my-8 disabled:cursor-not-allowed disabled:bg-gray-300"
						htmlType="button"
						disabled={!isFormComplete()}
						onClick={downloadSectorData}
					>
						Send Data
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default Download;
