import React, { useEffect, useState, useCallback } from "react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PrintSummary() {
	const [category, setCategory] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [subCategory, setSubCategory] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [subCategoryItem, setSubCategoryItem] = useState([]);
	const [selectedItem, setSelectedItem] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [buttonText, setButtonText] = useState("get Summary");
	const [summaryDetails, setSummaryDetails] = useState("");
	const [noRecords, setNoRecords] = useState("");
	const [tab, setTab] = useState(null);

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	useEffect(() => {
		if (!selectedCategory || !selectedSubCategory || !selectedItem) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [selectedCategory, selectedSubCategory, selectedItem]);

	const getCategory = useCallback(async () => {
		try {
			const fetchCategory = await Axios.post(`${baseUrl}/sectors`);
			const item = fetchCategory.data.data;

			setCategory(item);
		} catch (err) {
			console.log(err);
		}
	}, [baseUrl]);

	const handleSubCategory = useCallback(
		async (e) => {
			const curr = e.target.value;
			setSelectedCategory(curr);
			try {
				const fetch = await Axios.post(`${baseUrl}/category/${curr}`);
				const result = fetch.data.data;
				setSubCategory(result);
			} catch (err) {
				console.log(err);
			}
		},
		[baseUrl],
	);

	const handleSubCategoryItem = useCallback(
		async (e) => {
			const impt = e.target.value;
			setSelectedSubCategory(impt);
			try {
				const fetchItem = await Axios.post(
					`${baseUrl}//${selectedCategory}/${impt}`,
				);
				const subItem = fetchItem.data.data;
				setSubCategoryItem(subItem);
			} catch (err) {
				console.log(err);
			}
		},
		[selectedCategory, baseUrl],
	);

	useEffect(() => {
		getCategory();
	}, [getCategory]);

	const handleClick = async () => {
		setButtonText("Please wait...");
		const authToken = process.env.REACT_APP_AUTH;
		let subItem = JSON.parse(selectedItem);
		let subItemID = subItem?.id;

		try {
			let fetchData = await Axios.get(
				`${baseUrl}/subcategoryitems/${subItemID}/print/summary`,
				{
					headers: {
						Authorization: authToken,
					},
				},
			);
			if (fetchData.data.status === true) {
				setSummaryDetails(Object.values(fetchData.data.data));
				console.log(fetchData.data.data);
				setTab(1);
			} else if (fetchData.data.status === false) {
				setNoRecords(fetchData.data.message);
				setTab(2);
			}
			setButtonText("get summary");
		} catch (error) {
			setButtonText("get summary");
			toast.error("Error encountered. Try again shortly!", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 5000,
				hideProgressBar: false,
			});
		}
	};

	return (
		<FormWrapper>
			<ToastContainer />

			<h4 className="font-semibold text-green-400 tracking-wider my-3">
				A. View Print Summary
			</h4>
			<Divider sx={{ mb: 1 }} />
			<div>
				{tab === 1 && (
					<div className="flex items-center justify-center shadow-md space-x-10 bg-blue-300 p-4 font-semibold rounded-sm">
						<p>Printed: {summaryDetails[0] || 0}</p>
						<p>UnPrinted: {summaryDetails[1] || 0} </p>
					</div>
				)}
				{tab === 2 && (
					<div className="flex items-center justify-center shadow-md space-x-5 bg-blue-300 p-4 font-semibold rounded-sm">
						<p>{noRecords}</p>
					</div>
				)}
			</div>
			<TextField
				className="box"
				select
				required
				SelectProps={{
					native: true,
				}}
				sx={{
					width: "30ch",
					mt: 3,
					mr: 2,
					ml: 10,
				}}
				value={selectedCategory}
				onChange={handleSubCategory}
			>
				<option value="">Select Category</option>
				{category?.map((val, id) => (
					<option key={`${val}-${id}`} value={val}>
						{val}
					</option>
				))}
			</TextField>
			<TextField
				className="box"
				select
				required
				SelectProps={{
					native: true,
				}}
				sx={{
					width: "30ch",
					mt: 3,
					ml: 10,
				}}
				value={selectedSubCategory}
				onChange={handleSubCategoryItem}
			>
				<option value="">Select Subcategory</option>
				{subCategory?.map((options, name) => (
					<option key={`${options}-${name}`} value={options}>
						{options}
					</option>
				))}
			</TextField>

			<TextField
				className="box"
				select
				required
				SelectProps={{
					native: true,
				}}
				sx={{
					width: "30ch",
					mt: 2,
					ml: 10,
				}}
				value={selectedItem}
				onChange={(e) => setSelectedItem(e.target.value)}
			>
				<option value="">Select Subcategory item</option>
				{subCategoryItem?.map((opt, name) => (
					<option key={`${opt}-${name}`} value={JSON.stringify(opt)}>
						{opt.name}
					</option>
				))}
			</TextField>

			<Button
				sx={{
					mt: 3,
					width: "25ch",
					ml: 15,
					mb: 4,
				}}
				type="button"
				color="success"
				disabled={isDisabled}
				variant="contained"
				onClick={handleClick}
			>
				{buttonText}
			</Button>
			{/* <Button
				sx={{
					mt: 3,
					width: "25ch",
					ml: 15,
					mb: 4,
				}}
				type="button"
				color="success"
				variant="contained"
				onClick={downloadImages}
			>
				download
			</Button> */}
		</FormWrapper>
	);
}

export default PrintSummary;

const FormWrapper = styled.div`
	.verify {
		display: flex;
		align-items: center;
		flex-grow: auto;
		margin: 1rem;
	}

	.sub-title {
		background-color: #00c897;
		color: whitesmoke;
		padding: 5px 30px;
	}
	.sub-title2 {
		background-color: #00c897;
		color: whitesmoke;
		padding: 5px 30px;
		margin-right: 15rem;
	}
	.fac-1 {
		display: flex;
		align-items: center;
	}
`;
