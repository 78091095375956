import React, { useState, useEffect, useCallback } from "react";

import Axios from "axios";
import CountUp from "react-countup";
import { FaHospitalUser } from "react-icons/fa";
import { Line } from "rc-progress";
import { Card } from "antd";

function EncounterCard() {
	const [newEncounters, setNewEncounters] = useState("0");
	const [encounterIncrease, setEncounterIncrease] = useState("0");

	const baseURL = process.env.REACT_APP_BASE_URL;

	const getNewEncounters = useCallback(async () => {
		try {
			const fetchEncounters = await Axios.get(`${baseURL}/new_encounters`);
			setNewEncounters(fetchEncounters.data.new_encounters);
			setEncounterIncrease(fetchEncounters.data.percent_increase);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getNewEncounters();
	}, [getNewEncounters]);

	return (
		<Card
			title="TOTAL ENCOUNTERS"
			className="bg-green-400 text-center"
			bordered={true}
			style={{
				width: 350,
			}}
		>
			<div className="flex items-center space-x-10 my-5">
				<div className="bg-white p-5 opacity-80 rounded-md shadow-lg">
					<FaHospitalUser className="text-5xl group-hover:animate-pulse" />
				</div>

				<CountUp
					style={{
						fontSize: "40px",
						fontWeight: "700",
						color: "black",
						letterSpacing: "2px",
					}}
					start={0}
					end={newEncounters}
					duration={15}
					separator={","}
				/>
			</div>
			<div className="px-10">
				<Line
					percent={parseInt(encounterIncrease)}
					strokeWidth="3"
					strokeColor="red"
					strokeLinecap="round"
					style={{ width: "200px" }}
				/>

				<p className="font-semibold">
					{encounterIncrease === undefined ? "0" : parseInt(encounterIncrease)}%
					Increase in 28 Days
				</p>
			</div>
		</Card>
	);
}

export default EncounterCard;
