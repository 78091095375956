import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ToastContainer, toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Image } from "antd";

const url2 =
	"https://previews.123rf.com/images/azvector/azvector1802/azvector180200065/94912557-avatar-document-file-page-picture-profile-user-icon-vector-icon.jpg";
const url =
	"https://png.pngtree.com/png-vector/20190114/ourmid/pngtree-vector-avatar-icon-png-image_313572.jpg";

function FormalDependents({ enrollmentID, apiToken }) {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [listDependents, setListDependents] = useState([]);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [isOpen, setIsOpen] = useState({});
	const [showTable, setShowTable] = useState(false);

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	//fetch principal dependents
	const getDetails = async () => {
		const params = JSON.stringify({
			"enrollment id": enrollmentID,
		});

		Axios({
			method: "POST",
			url: `${baseUrl}/get/dependents`,
			headers: {
				"Content-Type": "application/json",
			},
			data: params,
		})
			.then((res) => {
				setListDependents(res.data.data);
				setShowTable(true);
			})

			.catch((error) => {
				toast.error("Verification error! Please try again later.!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: false,
				});
				console.log(error);
			});
	};

	const handleRemove = (detail) => {
		const params = JSON.stringify({
			"principal enrid": detail?.principals_enrid,
			"dependent id": detail?._id,
			mine: true,
		});

		Axios({
			method: "POST",
			url: `${baseUrl}/delete/dep/`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${apiToken}`,
			},
			data: params,
		})
			.then((response) => {
				console.log(response.status);
				if (response.status === 200) {
					toast.success("Dependent has been Removed successfully!", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});

					setListDependents((current) =>
						current.filter((dep) => {
							// 👇️ remove object that has id equal to the dependent id
							return dep._id !== detail._id;
						}),
					);
				} else {
					toast.error(
						"Authorization failed and Access denied. Try again later!",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			})
			.catch(function (error) {
				console.log(error);
				toast.error(
					"Error encountered or Authorization failed.Try again later!",
					{
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					},
				);
			});
	};

	return (
		<section>
			<ToastContainer />

			<div align="left">
				<Button
					fullWidth
					style={{ width: "30%", marginTop: "20px" }}
					onClick={() => getDetails()}
				>
					Get Dependents
				</Button>
			</div>
			{showTable && (
				<Table aria-label="collapsible table" className="border-2 my-4">
					<TableHead>
						<TableRow className="bg-green-700">
							<TableCell className="table-head" />

							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Name
							</TableCell>
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Gender
							</TableCell>
							<TableCell
								align="center"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Relationship with Principal
							</TableCell>
							<TableCell
								align="right"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Action
							</TableCell>
						</TableRow>
					</TableHead>
					{listDependents?.length > 0 &&
						listDependents?.map((user, indx) => {
							return (
								<TableBody key={indx}>
									<TableRow
										sx={{
											"& > *": {
												borderBottom: "unset",
											},
										}}
									>
										<TableCell>
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() =>
													setIsOpen((current) => ({
														[indx]: !current[indx],
													}))
												}
											>
												{isOpen[indx] ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)}
											</IconButton>
										</TableCell>

										<TableCell component="th" scope="row">
											{user?.name}
										</TableCell>
										<TableCell align="left">{user?.gender}</TableCell>
										<TableCell align="center">
											{user?.relationship_with_principal}
										</TableCell>
										<TableCell align="left">
											<Button
												onClick={() => handleRemove(user)}
												type="submit"
												title="Remove"
												color="error"
											>
												<HighlightOffIcon className="hover:animate-spin" />
											</Button>
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell
											style={{
												paddingBottom: 0,
												paddingTop: 0,
											}}
											colSpan={6}
										>
											<Collapse in={isOpen[indx]} timeout={600} unmountOnExit>
												<Box
													sx={{
														backgroundColor: "white",
														padding: 2,
													}}
												>
													<img
														src={user?.passport || url}
														alt="logo"
														onError={(e) => (e.target.src = url)}
														className="w-20 object-cover mx-auto rounded-full transition-transform hover:scale-105 duration-500 ease-in-out"
													/>
													<div className="flex justify-between">
														<div>
															<p className="my-3 font-cant text-gray-500 font-semibold">
																{" "}
																Name:{" "}
																<span className="text-green-600">
																	{" "}
																	{user?.name === ""
																		? "No Data"
																		: user?.name}{" "}
																</span>
															</p>
															<p className="my-3 font-cant text-gray-500 font-semibold">
																{" "}
																Gender:{" "}
																<span className="text-green-600">
																	{" "}
																	{user?.gender === ""
																		? "No Data"
																		: user?.gender}{" "}
																</span>
															</p>
															<p className="my-3 font-cant text-gray-500 font-semibold">
																{" "}
																Date of Birth:{" "}
																<span className="text-green-600">
																	{" "}
																	{user?.date_of_birth === ""
																		? "No Data"
																		: user?.date_of_birth}{" "}
																</span>
															</p>
															<p className="my-3 font-cant text-gray-500 font-semibold">
																{" "}
																Blood Group:{" "}
																<span className="text-green-600">
																	{" "}
																	{user?.blood_group === ""
																		? "No Data"
																		: user?.blood_group}{" "}
																</span>
															</p>
															<p className="my-3 font-cant text-gray-500 font-semibold">
																{" "}
																Genotype:{" "}
																<span className="text-green-600">
																	{" "}
																	{user?.genotype === ""
																		? "No Data"
																		: user?.genotype}{" "}
																</span>
															</p>
															<p className="my-3 font-cant text-gray-500 font-semibold">
																{" "}
																ID:{" "}
																<span className="text-green-600">
																	{" "}
																	{user?._id === ""
																		? "No Data"
																		: user?._id}{" "}
																</span>
															</p>
														</div>

														<div>
															<Image
																width={200}
																src={user?.id_photo || url2}
																alt="id_photo"
																fallback={url2}
																className="object-cover mx-auto py-4 transition-transform hover:scale-105 duration-500 ease-in-out"
															/>
															<h3 className="font-semibold italic text-center">
																ID Photo: {user?.id_type}
															</h3>
														</div>
													</div>
												</Box>
											</Collapse>
										</TableCell>
									</TableRow>
								</TableBody>
							);
						})}
				</Table>
			)}
		</section>
	);
}

export default FormalDependents;
