import React from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import CountUp from "react-countup";
import Divider from "@mui/material/Divider";

function TransactionItem({ Icon, title, data, bgcolor, color }) {
  return (
    <Wrapper>
      <Paper
        className="paper"
        elevation={5}
        style={{ backgroundColor: bgcolor }}
      >
        <TopWrapper>
          {Icon}
          <h3 className="font-cant font-semibold text-lg tracking-wider mb-4">
            {title}
          </h3>
        </TopWrapper>
        <Divider />
        <BottomWrapper>
          <span style={{ fontSize: "2rem" }}>
            &#8358;
            <CountUp
              style={{
                fontSize: "2rem",
                fontWeight: "800",
                color: color,
                letterSpacing: "3px",
              }}
              start={0}
              end={data}
              duration={2}
              separator={","}
              startOnMount={true}
            />
          </span>
        </BottomWrapper>
      </Paper>
    </Wrapper>
  );
}

export default TransactionItem;

const Wrapper = styled.div`
  padding: 2rem;
  text-align: center;
  .paper {
    padding: 2rem;
    width: 20vw;
    height: 30vh;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const BottomWrapper = styled.div`
  padding: 1rem;
`;
