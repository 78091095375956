import React, { useEffect, useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Item({ apiToken }) {
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [addSelectedItem, setAddSelectedItem] = useState("");
  const [hmo, setHmo] = useState([]);
  const [selectedHmo, setSelectedHmo] = useState("");
  const [buttonText, setButtonText] = useState("Submit");

  //base_url
  const api = process.env.REACT_APP_BASE_URL;

  const getCategory = useCallback(async () => {
    try {
      const fetchCategory = await Axios.post(`${api}/sectors`);
      const item = fetchCategory.data.data;

      setCategory(item);
    } catch (err) {
      console.log(err);
    }
  }, [api]);

  const handleSubCategory = useCallback(
    async (e) => {
      const curr = e.target.value;
      setSelectedCategory(curr);
      try {
        const fetch = await Axios.post(`${api}/category/${curr}`);
        const result = fetch.data.data;
        setSubCategory(result);
      } catch (err) {
        console.log(err);
      }
    },
    [api]
  );

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const getHmo = useCallback(async () => {
    try {
      const fetchHmo = await Axios.post(`${api}/hmo`);
      const resultHmo = fetchHmo.data.data;
      setHmo(resultHmo);
    } catch (err) {
      console.log(err);
    }
  }, [api]);

  useEffect(() => {
    getHmo();
  }, [getHmo]);

  const addItem = (e) => {
    e.preventDefault();
    setButtonText("Submitting...");
    let myHmo = JSON.parse(selectedHmo);

    const itemData = JSON.stringify({
      category: selectedCategory,
      subcategory: selectedSubCategory,
      hmo: { name: myHmo?.name, id: myHmo?.id },
      name: addSelectedItem,
    });

    Axios({
      method: "POST",
      url: `${api}/new/item`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiToken}`,
      },
      data: itemData,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("item saved Successfully !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          });
          setButtonText("Submit");
          setSelectedCategory("");
          setSelectedSubCategory("");
          setAddSelectedItem("");
          setSelectedHmo("");
        } else {
          setButtonText("Submit");
          toast.error(
            "Authorization failed and Access denied. Try again later!",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: false,
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        setButtonText("Submit");
        toast.error(
          "Error encountered or Authorization failed.Try again later!",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          }
        );
      });
  };

  return (
    <FormWrapper>
      <ToastContainer />

      <form
        onSubmit={addItem}
        className="flex flex-col items-center my-4 mx-auto shadow-xl rounded-md bg-[whitesmoke] p-5"
      >
        <div className="bg-[#00C897] py-4 w-full rounded-md my-2 mx-auto">
          <h2 className="font-pop font-semibold text-xl text-center">
            {" "}
            Add Subcategory items
          </h2>
        </div>

        <TextField
          className="box"
          select
          required
          SelectProps={{
            native: true,
          }}
          sx={{
            width: "35ch",
            mt: 3,
            mb: 2,
          }}
          value={selectedCategory}
          onChange={handleSubCategory}
        >
          <option value="">Select Category</option>
          {category?.map((val, id) => (
            <option key={`${val}-${id}`} value={val}>
              {val}
            </option>
          ))}
        </TextField>

        <TextField
          className="box"
          select
          required
          SelectProps={{
            native: true,
          }}
          sx={{
            width: "35ch",
            mt: 3,
            mb: 4,
          }}
          onChange={(e) => setSelectedSubCategory(e.target.value)}
          value={selectedSubCategory}
        >
          <option value="">Select Subcategory</option>
          {subCategory?.map((options, idx) => (
            <option key={`${options}-${idx}`} value={options}>
              {options}
            </option>
          ))}
        </TextField>
        <TextField
          required
          className="box"
          select
          name="hmo"
          SelectProps={{
            native: true,
          }}
          sx={{
            width: "35ch",

            mb: 4,
          }}
          value={selectedHmo}
          onChange={(e) => setSelectedHmo(e.target.value)}
        >
          <option>Select HMO</option>
          {hmo?.map((res) => (
            <option value={JSON.stringify(res)} key={res.id}>
              {res.name}
            </option>
          ))}
        </TextField>

        <TextField
          className="box"
          required
          SelectProps={{
            native: true,
          }}
          placeholder="Enter name of subcategory item"
          sx={{
            width: "35ch",
          }}
          onChange={(e) => setAddSelectedItem(e.target.value)}
          value={addSelectedItem}
        />

        <Button
          className="submit"
          type="submit"
          disabled={
            !addSelectedItem || !selectedSubCategory || !selectedCategory
              ? true
              : false
          }
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, ml: 3, width: "30ch" }}
        >
          {buttonText}
        </Button>
      </form>
    </FormWrapper>
  );
}

export default Item;

const FormWrapper = styled.div`
  margin: 0 5rem 0 10rem;
`;
