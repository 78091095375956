import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import CountUp from "react-countup";
import { FaUserEdit } from "react-icons/fa";
import { Line } from "rc-progress";
import { Card } from "antd";

function EnrollmentCard() {
	const [newEnrollments, setNewEnrollments] = useState(450);
	const [enrollmentIncrease, setEnrollmentIncrease] = useState("0%");

	const baseURL = process.env.REACT_APP_BASE_URL;

	const getNewEnrollments = useCallback(async () => {
		try {
			const fetchEnrollments = await Axios.get(`${baseURL}/new_enrollments`);
			setNewEnrollments(fetchEnrollments.data.new_enrollments);
			setEnrollmentIncrease(fetchEnrollments.data.percent_increase);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getNewEnrollments();
	}, [getNewEnrollments]);

	return (
		<Card
			title="NEW ENROLLMENTS"
			className="bg-cyan-500 text-center"
			bordered={true}
			style={{
				width: 350,
			}}
		>
			{/* <h3 className="text-xl font-cant text-center font-semibold tracking-wider">
				NEW ENROLLMENTS
			</h3> */}

			<div className="flex items-center space-x-10 my-5">
				<div className="bg-white p-5 opacity-80 rounded-md shadow-lg">
					<FaUserEdit className="text-5xl group-hover:animate-pulse" />
				</div>

				<CountUp
					style={{
						fontSize: "40px",
						fontWeight: "700",
						color: "black",
						letterSpacing: "2px",
					}}
					start={1000}
					end={newEnrollments}
					duration={15}
					separator={","}
				/>
			</div>

			<div className="px-10">
				<Line
					percent={parseInt(enrollmentIncrease)}
					strokeWidth="3"
					strokeColor="red"
					strokeLinecap="round"
					style={{ width: "250px" }}
				/>

				<p className="font-semibold">
					{" "}
					{enrollmentIncrease === undefined
						? "0"
						: parseInt(enrollmentIncrease)}
					% Increase in 90 Days
				</p>
			</div>

			{/* </section> */}
		</Card>
	);
}

export default EnrollmentCard;
