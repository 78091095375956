import React, { useEffect, useState, useCallback } from "react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Subcategory({ apiToken }) {
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategoryValue, setSubcategoryValue] = useState("");
  const [buttonText, setButtonText] = useState("Submit");

  //base_url
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const getCategory = useCallback(async () => {
    try {
      const fetchCategory = await Axios.post(`${baseUrl}/sectors`);
      const item = fetchCategory.data.data;

      setCategory(item);
    } catch (err) {
      console.log(err);
    }
  }, [baseUrl]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const addSubcategory = (e) => {
    e.preventDefault();
    setButtonText("Submitting...");
    const itemData = JSON.stringify({
      category: selectedCategory,
      "new subcateogry": subcategoryValue,
    });

    Axios({
      method: "POST",
      url: `${baseUrl}/add/subcategory`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiToken}`,
      },
      data: itemData,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Subcategory saved Successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          });
          setButtonText("Submit");
          setSelectedCategory("");
          setSubcategoryValue("");
        } else {
          setButtonText("Submit");
          toast.error(
            "Authorization failed and Access denied. Try again later!",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: false,
            }
          );
        }
      })
      .catch(function (error) {
        setButtonText("Submit");
        console.log(error);
        toast.error(
          "Error encountered or Authorization failed.Try again later!",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          }
        );
      });
  };

  return (
    <FormWrapper>
      <ToastContainer />
      <form
        onSubmit={addSubcategory}
        className="flex flex-col items-center my-4 mx-auto shadow-xl rounded-md bg-[whitesmoke] p-5"
      >
        <div className="bg-[#2FA4FF] py-4 w-full rounded-md my-2 mx-auto">
          <h2 className="font-pop font-semibold text-xl text-center">
            Add SubCategory
          </h2>
        </div>
        <Divider />
        <TextField
          className="box"
          select
          required
          SelectProps={{
            native: true,
          }}
          sx={{
            width: "28ch",
            mt: 4,
            mb: 3,
          }}
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          {category?.map((val) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </TextField>

        <TextField
          className="box"
          required
          SelectProps={{
            native: true,
          }}
          sx={{
            width: "28ch",
            mt: 2,
          }}
          placeholder="Enter Subcategory"
          onChange={(e) => setSubcategoryValue(e.target.value)}
          value={subcategoryValue}
        />

        <Button
          className="submit"
          type="submit"
          disabled={!selectedCategory || !subcategoryValue ? true : false}
          fullWidth
          variant="contained"
          sx={{ mt: 7, mb: 7, width: "30ch" }}
        >
          {buttonText}
        </Button>
      </form>
    </FormWrapper>
  );
}

export default Subcategory;

const FormWrapper = styled.div`
  margin: 0 5rem 0 10rem;
`;
