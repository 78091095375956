import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import { Routes, Route, useNavigate } from "react-router-dom";
import Main from "./components/Main";
import InnerPage from "./components/pages/InnerPage";
import Login from "./components/SignIn";
import Formal from "./components/pages/FormalSector";
import Equity from "./components/pages/EquitySector";
import Informal from "./components/pages/InformalSector";
import Settings from "./components/features/Settings";
import Transaction from "./components/features/Transaction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Users from "./UserLogin";

function App() {
	const navigate = useNavigate();
	const [apiToken, setApiToken] = useState("");
	const login = (details) => {
		const validUser = Users.find((user) => {
			const email = details.email;
			const password = details.password;
			return user.email === email && user.password === password;
		});

		if (validUser) {
			sessionStorage.setItem("email_user", details.email);
			toast.success(`Welcome ${validUser.name}`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 4000,
				hideProgressBar: true,
			});
			navigate("/");
		} else {
			toast.error("🤦‍♂️ Invalid email or password! Please check your entries.", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 5000,
				hideProgressBar: true,
			});
		}
	};

	return (
		<div className="app">
			<ToastContainer />
			{!sessionStorage.getItem("email_user") ? (
				<Login login={login} />
			) : (
				<>
					<Header setApiToken={setApiToken} />
					<div className="app-body">
						<Routes>
							<Route path="/" element={<Main />} />
							<Route
								path="/formalsector/*"
								element={<Formal apiToken={apiToken} />}
							/>
							<Route
								path="/settings/*"
								element={<Settings apiToken={apiToken} />}
							/>
							<Route path="/informalsector/*" element={<Informal />} />
							<Route path="/equitysector/*" element={<Equity />} />
							<Route path="/mailbox" element={<InnerPage />} />
							<Route path="/approvedenrollments/*" element={<InnerPage />} />
							<Route path="/pendingenrollment/*" element={<InnerPage />} />
							<Route path="/subscription/*" element={<InnerPage />} />
							<Route path="/facility/*" element={<InnerPage />} />
							<Route path="/encounter/*" element={<InnerPage />} />
							<Route path="/transaction/*" element={<Transaction />} />
						</Routes>
					</div>
				</>
			)}
		</div>
	);
}

export default App;
