import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "./SidebarData";
import { useDispatch } from "react-redux";
import { Encounters, Subscriptions, Facilities } from "../../redux/TitleSlice";
import Axios from "axios";

function General({ setApiToken }) {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();

	//when  a user clicks the configuration menu a token is generated for authorization and then access API payloads
	//under the settings menu
	const handleClick = (yul) => {
		const api = process.env.REACT_APP_BASE_URL;
		if (yul.text === "Configuration" || yul.text === "Formal Sector") {
			Axios({
				method: "POST",
				url: `${api}/get/token`,
			})
				.then((res) => {
					setApiToken(res.data.data);
				})
				.catch((err) => {
					console.log("error", err);
				});
		} else return;
	};

	return (
		<Wrapper>
			{Sidebar.map((item, id) => {
				return (
					<div key={item.title}>
						<ListSubheader inset className="header">
							{item.header}
						</ListSubheader>
						<ListItem
							button
							onClick={() =>
								setOpen((current) => ({
									[id]: !current[id],
								}))
							}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText primary={item.title} />
							{open[id] ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
						</ListItem>
						<Collapse
							in={open[id]}
							timeout={800}
							unmountOnExit
							sx={{ transitionTimingFunction: "ease-in--out" }}
						>
							<>
								{item.options?.map((yul, idx) => {
									return (
										<List key={idx} component="div" disablePadding>
											<ListItem
												sx={{ flexDirection: "column", marginTop: "-1.3rem" }}
											>
												<NavLink
													exact="true"
													to={yul.path}
													style={{
														color:
															location.pathname === yul.path && "forestgreen",
													}}
												>
													<p
														onClick={() => {
															if (item.title === "Subscriptions") {
																return dispatch(Subscriptions());
															} else if (item.title === "Encounters") {
																return dispatch(Encounters());
															} else if (item.title === "Facilities") {
																return dispatch(Facilities());
															}
														}}
														className="flex items-center py-3 gap-5"
													>
														{yul.con}

														<span
															className={`${
																yul.text === "Facility Encounters"
																	? "pointer-events-none"
																	: "pointer-events-auto"
															}  font-pop font-semibold text-sm`}
															onClick={() => handleClick(yul)}
														>
															{yul.text}
														</span>
													</p>
												</NavLink>
											</ListItem>
										</List>
									);
								})}
							</>
						</Collapse>
						{item.divider}
					</div>
				);
			})}
		</Wrapper>
	);
}

export default General;

const Wrapper = styled.div`
	.header {
		font-weight: 700;
		font-size: 14px;
		color: black;
		letter-spacing: 3px;
	}
`;
