import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Routes, Route } from "react-router-dom";
import { Button } from "@mui/material";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import HistoryIcon from "@mui/icons-material/History";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import TransactionOverview from "./TransactionOverview";
import Payment from "./Payment";
import Bank from "./Bank";
import Withdrawal from "./Withdrawal";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Designed for Plateau State Contributory Health Care Management Agency by{" "}
      <a className="link" href="https://instantdeposit.africa">
        Instant Deposit Ltd.
      </a>{" "}
      All Rights Reserved.
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const mdTheme = createTheme();
const settings = [
  {
    icon: <ViewComfyIcon />,
    text: "Transaction Overview",
    link: "transactionoverview",
  },
  {
    icon: <HowToVoteIcon />,
    text: "Withdrawal History",
    link: "withdrawal",
  },
  {
    icon: <HistoryIcon />,
    text: "Payment History",
    link: "payment",
  },
  {
    icon: <ContactPageIcon />,
    text: "Bank Details",
    link: "bank",
  },
];

function Transactions() {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <TopWrapper>
                <Paper className="paper">
                  <Button
                    className="btn"
                    startIcon={<AccountBalanceWalletIcon fontSize="large" />}
                  >
                    TRANSACTIONS
                  </Button>
                  <div>
                    <h4>Folders</h4>
                    <Divider />
                  </div>
                  {settings.map((item, idx) => {
                    return (
                      <NavLink exact="true" to={item.link} className="box" key={idx}>
                        <MenuItem className="sidebarOption">
                          {item.icon}
                          {item.text}
                        </MenuItem>
                      </NavLink>
                    );
                  })}
                </Paper>
              </TopWrapper>
            </Grid>

            <Grid item xs={12} md={8} lg={9}>
              <div>
                <Routes>
                  <Route
                    path="/transactionoverview"
                    element={<TransactionOverview />}
                  />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/bank" element={<Bank />} />
                  <Route path="/withdrawal" element={<Withdrawal />} />
                </Routes>
              </div>
            </Grid>
          </Grid>
          <Copyright sx={{ pt: 5 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Transactions;
const TopWrapper = styled.div`
  .paper{
      padding: 2px,
      display: flex;
      flex-direction: column;
      height: 600px;
      width: 340px;
  
  }
  .btn{
    background-color: green;
    color: white;
    padding: 15px 40px;
    margin: 15px 50px;
  }
  h4{
    margin: 20px 25px;
  }
  .sidebarOption {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: gap;
    height: 40px;
    padding: 30px 5px;
    color: #818589;
    font-weight: 800;
    cursor: pointer;
    font-family: "Poppins";
    text-decoration: none;
  }
  .sidebarOption:hover {
    border-radius: 0 120px 120px 0;
    font-weight: 800 !important;
    border-left: 3px solid forestgreen;
    color: black;
  }
  
  .box {
    text-decoration: none;
  }
  
`;
