import { createSlice } from "@reduxjs/toolkit";

export const TitleSlice = createSlice({
  name: "title",
  initialState: {
    title: "",
  },
  reducers:{
    Subscriptions: (state) => {
      state.title = "SUBSCRIPTIONS";
    },
    Encounters: (state) => {
      state.title = "ENCOUNTERS";
    },
    Facilities: (state) => {
      state.title = "FACILITIES";
    },
    Settings: (state) => {
      state.title = "SETTINGS";
    },
    

  },
});
export const { Subscriptions, Encounters, Facilities, Settings } =
  TitleSlice.actions;

//selectors
export const myTitle = (state) => state.title.title;


export default TitleSlice.reducer;