import React, { useEffect, useState, useCallback } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import PrintSummary from "./PrintSummary";
//import AnimatedBar from "./AnimatedBar";

function Download({ apiToken }) {
	const [category, setCategory] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [subCategory, setSubCategory] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [subCategoryItem, setSubCategoryItem] = useState([]);
	const [selectedItem, setSelectedItem] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [isDisabled2, setIsDisabled2] = useState(false);
	const [buttonText, setButtonText] = useState("Get Images");
	const [showDownload, setShowDownload] = useState(false);
	const [selectedPage, setSelectedPage] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [imageUrls, setImageUrls] = useState([]);
	const [buttonText2, setButtonText2] = useState("Download Images");
	//const [percentage, setPercentage] = useState(0);

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	//fetch images effect
	useEffect(() => {
		if (
			!selectedCategory ||
			!selectedSubCategory ||
			!selectedItem ||
			!selectedPage ||
			!selectedStatus
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [
		selectedCategory,
		selectedSubCategory,
		selectedItem,
		selectedPage,
		selectedStatus,
	]);

	//download images effect
	useEffect(() => {
		if (imageUrls.length > 0) {
			setIsDisabled2(false);
		} else {
			setIsDisabled2(true);
		}
	}, [imageUrls.length]);

	const getCategory = useCallback(async () => {
		try {
			const fetchCategory = await Axios.post(`${baseUrl}/sectors`);
			const item = fetchCategory.data.data;

			setCategory(item);
		} catch (err) {
			console.log(err);
		}
	}, [baseUrl]);

	const handleSubCategory = useCallback(
		async (e) => {
			const curr = e.target.value;
			setSelectedCategory(curr);
			try {
				const fetch = await Axios.post(`${baseUrl}/category/${curr}`);
				const result = fetch.data.data;
				setSubCategory(result);
			} catch (err) {
				console.log(err);
			}
		},
		[baseUrl],
	);

	const handleSubCategoryItem = useCallback(
		async (e) => {
			const impt = e.target.value;
			setSelectedSubCategory(impt);
			try {
				const fetchItem = await Axios.post(
					`${baseUrl}//${selectedCategory}/${impt}`,
				);
				const subItem = fetchItem.data.data;
				setSubCategoryItem(subItem);
			} catch (err) {
				console.log(err);
			}
		},
		[selectedCategory, baseUrl],
	);

	useEffect(() => {
		getCategory();
	}, [getCategory]);

	//click to download images
	const handleClick = () => {
		setButtonText("Fetching Images...");
		const authToken = process.env.REACT_APP_AUTH;
		const newItem = JSON.parse(selectedItem);
		const result = newItem?.id;

		let cardData = new FormData();
		cardData.append("subcategoryiid", result);
		cardData.append("page_no", selectedPage);
		cardData.append("print_status", selectedStatus);

		Axios({
			url: `${baseUrl}/subcat/cards`,
			method: "POST",
			headers: {
				Authorization: authToken,
				ContentType: "multipart/form-data",
			},
			data: cardData,
		})
			.then((res) => {
				setImageUrls(res.data.data);

				setIsDisabled(true);
				setSelectedCategory("");
				setSelectedSubCategory("");
				setSelectedItem("");
				setSelectedStatus("");
				setButtonText("Get Images");
				toast.success(
					`Total Images to download is: ${res.data.data.length}.
					Please click the download Images button to begin download to your computer`,
					{
						position: toast.POSITION.TOP_CENTER,
						autoClose: 7000,
						hideProgressBar: false,
					},
				);
			})
			.catch((err) => {
				console.log(err);
				const title = newItem.name;
				if (err.response.status === 404) {
					toast.info(`No Records found for ${title}`, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});
				} else {
					toast.error(
						"Download error or Authorization failed. Try again later!",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
				setButtonText("Get Images");
			});
	};

	const downloadImages = async () => {
		toast.success("Images are downloading...please be patient", {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 3000,
			hideProgressBar: false,
		});
		const authToken = process.env.REACT_APP_AUTH;

		for (let i = 0; i < imageUrls.length; i++) {
			setButtonText2("Downloading in Progress...");
			try {
				const response = await Axios.get(imageUrls[i], {
					responseType: "arraybuffer",
					headers: {
						Authorization: authToken,
					},
				});

				downloadFile(response.data, imageUrls[i].split("/").pop());
				await Promise.all(downloadFile);
			} catch (error) {
				console.log(`Error downloading image: ${imageUrls[i]}`);
				setButtonText2("Download Images");
			}
		}
		setButtonText2("Download Complete");
		setIsDisabled2(true);
		toast.info(
			"Download completed. Please refresh the page before initiating another download",
			{
				position: toast.POSITION.TOP_CENTER,
				autoClose: 8000,
				hideProgressBar: false,
			},
		);
	};
	const downloadFile = (data, fileName) => {
		const blob = new Blob([data], { type: "image/jpeg" });
		saveAs(blob, fileName);
	};

	return (
		<FormWrapper>
			<ToastContainer />
			<Paper className="paper">
				<h2 className="text-xl font-pop font-semibold tracking-wider text-center py-3 rounded-md">
					Card Data
				</h2>
				<PrintSummary />

				<Divider sx={{ mb: 1 }} />
				<div align="right">
					<Button
						fullWidth
						variant="contained"
						sx={{
							mt: 2,
							width: "23ch",
							mb: 1,
							backgroundColor: "#00B4D8",
						}}
						onClick={() => setShowDownload(!showDownload)}
					>
						Download Card?
					</Button>
				</div>
				{showDownload && (
					<div>
						<h4 className="font-semibold text-green-400 tracking-wider my-3">
							B. Download Card
						</h4>

						<TextField
							className="box"
							select
							required
							SelectProps={{
								native: true,
							}}
							sx={{
								width: "30ch",
								mt: 3,
								ml: 10,
								mr: 2,
							}}
							value={selectedCategory}
							onChange={handleSubCategory}
						>
							<option value="">Select Category</option>
							{category?.map((val, id) => (
								<option key={`${val}-${id}`} value={val}>
									{val}
								</option>
							))}
						</TextField>
						<TextField
							className="box"
							select
							required
							SelectProps={{
								native: true,
							}}
							sx={{
								width: "30ch",
								mt: 3,
								ml: 10,
							}}
							value={selectedSubCategory}
							onChange={handleSubCategoryItem}
						>
							<option value="">Select Subcategory</option>
							{subCategory?.map((options, name) => (
								<option key={`${options}-${name}`} value={options}>
									{options}
								</option>
							))}
						</TextField>

						<TextField
							className="box"
							select
							required
							SelectProps={{
								native: true,
							}}
							sx={{
								width: "30ch",
								mt: 2,
								ml: 10,
							}}
							value={selectedItem}
							onChange={(e) => setSelectedItem(e.target.value)}
						>
							<option value="">Select Subcategory item</option>
							{subCategoryItem?.map((opt, name) => (
								<option key={`${opt}-${name}`} value={JSON.stringify(opt)}>
									{opt.name}
								</option>
							))}
						</TextField>
						<div className="flex items-center justify-around">
							<TextField
								className="box"
								select
								required
								SelectProps={{
									native: true,
								}}
								sx={{
									width: "20ch",
									mt: 2,
								}}
								value={selectedStatus}
								onChange={(e) => setSelectedStatus(e.target.value)}
							>
								<option value="">Select Print Status</option>

								<option value="true">True(Reprint)</option>
								<option value="false">False(New Print)</option>
							</TextField>
							<TextField
								className="box"
								placeholder="Page No (500/download)"
								required
								SelectProps={{
									native: true,
								}}
								sx={{
									width: "20ch",
									mt: 2,
								}}
								value={selectedPage}
								onChange={(e) => setSelectedPage(e.target.value)}
							/>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-10">
							<Button
								type="button"
								color="success"
								disabled={isDisabled}
								variant="contained"
								onClick={handleClick}
							>
								{buttonText}
							</Button>

							<Button
								type="button"
								color="primary"
								disabled={isDisabled2}
								variant="contained"
								onClick={downloadImages}
							>
								{buttonText2}
							</Button>
						</div>
					</div>
				)}
			</Paper>
		</FormWrapper>
	);
}

export default Download;

const FormWrapper = styled.div`
	margin: 0 3rem 0 17rem;
	.paper {
		padding: 1.5rem;
		margin-top: -2px;
		margin-left: -7rem;
		display: flex;
		flex-direction: column;
		height: auto;
		width: 470px;
	}
	.verify {
		display: flex;
		align-items: center;
		flex-grow: auto;
		margin: 1rem;
	}

	.sub-title {
		background-color: #00c897;
		color: whitesmoke;
		padding: 5px 30px;
	}
	.sub-title2 {
		background-color: #00c897;
		color: whitesmoke;
		padding: 5px 30px;
		margin-right: 15rem;
	}
	.fac-1 {
		display: flex;
		align-items: center;
	}
`;
