import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			Designed for Plateau State Contributory Health Care Management Agency by{" "}
			<a className="link" href="https://instantdeposit.africa">
				Instant Deposit Ltd.
			</a>{" "}
			All Rights Reserved.
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const theme = createTheme();

export default function SignIn({ login }) {
	const [details, setDetails] = useState({ email: "", password: "" });

	const handleSubmit = (e) => {
		e.preventDefault();
		login(details);
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 5,
						marginLeft: 7,
						marginRight: -15,
						display: "flex",
						backgroundColor: "whitesmoke",
						flexDirection: "column",
						alignItems: "center",
						padding: 5,
					}}
				>
					<div className="login-img" align="center">
						<img
							src="https://plateauhealth.online/assets/images/plaschema-logo.png"
							alt="logo"
						/>
					</div>
					<Typography component="h1" variant="h5" sx={{ mt: 2 }}>
						Dashboard Sign in
					</Typography>
					<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							value={details.email}
							onChange={(e) =>
								setDetails({ ...details, email: e.target.value })
							}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							value={details.password}
							onChange={(e) =>
								setDetails({ ...details, password: e.target.value })
							}
						/>

						<Button
							className="submit"
							type="submit"
							disabled={
								details.email === "" || details.password === "" ? true : false
							}
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Sign In
						</Button>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4, ml: 5, mr: -15 }} />
			</Container>
		</ThemeProvider>
	);
}
