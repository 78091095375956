import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Principal from "./Pricipal";
import Overview from "./charts/OverviewChart";
import EncounterChart from "./charts/Encounter";
import FormalChart from "./charts/Formal";
import EnrollmentCard from "./cards/EnrollmentCard";
import EncounterCard from "./cards/EncounterCard";
import FacilityCard from "./cards/FacilityCard";
import HmoTable from "./hmo/HMO";
import "./Main.css";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			Designed for Plateau State Contributory Health Care Management Agency by{" "}
			<a className="link" href="https://instantdeposit.africa">
				Instant Deposit Ltd.
			</a>{" "}
			All Rights Reserved.
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const mdTheme = createTheme();

function Main() {
	return (
		<ThemeProvider theme={mdTheme}>
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? theme.palette.grey[200]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: "100vh",
					overflow: "auto",
				}}
			>
				<Toolbar />
				<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
					<Grid container spacing={3}>
						<div className="flex items-center mt-5 mx-auto">
							<div className="w-[25rem] ml-2 shadow-xl bg-white p-3 ">
								<Principal />
							</div>

							{/* Chart */}

							<Paper className="overview-chart">
								<Overview />
							</Paper>
						</div>
						<div className="flex items-center space-x-16">
							<Paper className="encounter-chart">
								<EncounterChart />
							</Paper>

							<Paper className="formal-chart">
								<FormalChart />
							</Paper>
						</div>

						<div className="site-card-border-less-wrapper grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-4 mx-auto gap-4">
							<div className="">
								<EnrollmentCard />
							</div>
							<div>
								<EncounterCard />
							</div>
							<div>
								<FacilityCard />
							</div>
						</div>

						{/* Approved HMOs */}
						<Grid item xs={12}>
							<Paper
								sx={{
									p: 0,
									display: "flex",
									flexDirection: "column",
									width: 1050,
								}}
							>
								<HmoTable />
							</Paper>
						</Grid>
						<div></div>
					</Grid>
					<Copyright sx={{ pt: 4 }} />
				</Container>
			</Box>
		</ThemeProvider>
	);
}

export default Main;
