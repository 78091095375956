import React, { useEffect, useState, useCallback } from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Axios from "axios";
import CountUp from "react-countup";
import InformalDetails from "./InformalDetails";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			Designed for Plateau State Contributory Health Care Management Agency by{" "}
			<a className="link" href="https://instantdeposit.africa">
				Instant Deposit Ltd.
			</a>{" "}
			All Rights Reserved.
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

function Informal() {
	const [subCategory, setSubcategory] = useState([]);
	const [selectedSubCategory, setSelectedSubCategory] = useState(["Sponsored"]);

	const [categoryItems, setCategoryItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState("");
	const [itemCount, setItemCount] = useState([]);

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const getSubcategory = useCallback(async () => {
		try {
			const fetchLocal = await Axios.post(`${baseUrl}/sector/Informal`);
			const item = Object.keys(fetchLocal.data.data);
			setSubcategory(item);

			await Axios.post(`${baseUrl}/Informal/${selectedSubCategory}/items`).then(
				(response) => {
					const myMda = Object.entries(response.data.data);
					const countMda = Object.values(response.data.data);

					setCategoryItems(myMda);
					setItemCount(countMda);
					setSelectedItem(myMda[1][0]);
				},
			);
		} catch (err) {
			console.log(err);
		}
	}, [selectedSubCategory, baseUrl]);

	const handleMda = useCallback(
		async (e) => {
			const result = e.target.value;
			setSelectedSubCategory(result);

			await Axios.post(`${baseUrl}/Informal/${result}/items`).then(
				(response) => {
					const myMda = Object.entries(response.data.data);

					setCategoryItems(myMda);
				},
			);
		},
		[baseUrl],
	);

	const handleFac = (curr) => {
		setSelectedItem(curr[0]);
	};

	useEffect(() => {
		getSubcategory();
	}, [getSubcategory]);

	return (
		<section className="flex flex-col bg-gray-100 h-[100vh] overflow-auto">
			<div className="flex items-center justify-between mb-7">
				<div className="text-white font-medium shadow-lg bg-[#373a45] p-4 w-[30vw] mt-[3rem] ml-[1rem] h-[80vh] overflow-auto rounded-sm">
					<InputLabel sx={{ color: "white" }}>SUBCATEGORY</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						sx={{
							width: "40ch",
							color: "black",
							backgroundColor: "white",
						}}
						value={selectedSubCategory}
						onChange={handleMda}
					>
						<MenuItem value="">Select Subcategory</MenuItem>
						{subCategory?.map((records, id) => (
							<MenuItem key={`${records}-${id}`} value={records}>
								{records}
							</MenuItem>
						))}
					</Select>

					<div className="flex justify-between mt-5 mb-2">
						<h4 className="font-cant font-semibold text-green-300 tracking-wider">
							Subcategory Items
						</h4>
						<h4>
							Total:
							<span>
								{" "}
								<CountUp
									className="text-xl font-semibold text-green-300 tracking-wider"
									start={0 || "No record yet"}
									end={itemCount.reduce((a, b) => a + b, 0)}
									duration={5}
									separator={","}
								/>
							</span>
						</h4>
					</div>

					<Divider />

					<div className="my-5">
						{categoryItems?.map((curr, idx) => (
							<div className="flex justify-between" key={idx}>
								<p
									className="mb-5 pl-1 cursor-pointer hover:border-l-4 border-green-400"
									onClick={() => handleFac(curr)}
								>
									{curr[0]}
								</p>
								<div className="">
									<p className="font-spec tracking-wider  text-center">
										{curr[1]}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>

				<div>
					<InformalDetails itemTitle={selectedItem} />
				</div>
			</div>

			<Copyright sx={{ pt: 4 }} />
		</section>
	);
}

export default Informal;
