import React, { useState, useCallback, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Axios from "axios";
import { Box } from "@mui/material";

const EquityDetails = ({ itemTitle }) => {
	const [equityDetails, setEquityDetails] = useState([]);
	const [open, setOpen] = useState({});
	const [loading, setLoading] = useState(true);
	const [loader, setLoader] = useState(true);
	const [limit, setLimit] = useState(100);
	const [error, setError] = useState("");

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const url =
		"https://png.pngtree.com/png-vector/20190114/ourmid/pngtree-vector-avatar-icon-png-image_313572.jpg";

	const getEquityDetails = useCallback(async () => {
		setLoader(true);
		await Axios.post(`${baseUrl}/${itemTitle}/records?limit=${limit}&skip=0`)
			.then((response) => {
				const details = response.data.Details;
				if (response.data.status === true && details.length > 0) {
					setEquityDetails(details);
				} else if (response.data.status === true && details.length === 0) {
					setError("Enrollments Ongoing...");
					setEquityDetails([]);
				} else {
					setError("Enrollments Ongoing...");
				}
				setLoader(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [itemTitle, limit, baseUrl]);

	useEffect(() => {
		getEquityDetails();
	}, [getEquityDetails]);

	const getEquityDetails2 = useCallback(async () => {
		setLoading(true);
		try {
			const fetch = await Axios.post(
				`${baseUrl}/${itemTitle}/records?limit=${limit}&skip=0`,
			);
			setLoading(false);
			const result = fetch.data.Details;
			return result;
		} catch (err) {
			console.log(err);
		}
	}, [limit, itemTitle, baseUrl]);

	const fetchData = async () => {
		const nextPage = await getEquityDetails2();
		setEquityDetails([...equityDetails, ...nextPage]);
		if (nextPage.length === 0) {
			return;
		}
		setLimit(limit + 50);
	};

	return (
		<div className="shadow-lg bg-white p-4 w-[60vw] mt-28 ml-[1rem] h-[90vh] overflow-auto rounded-sm">
			<h4 className="text-xl font-cant font-semibold tracking-wide my-5">
				Equity Sector Beneficiaries:{" "}
				<span style={{ color: "green" }}>{itemTitle}</span>{" "}
			</h4>

			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow className="bg-gray-500">
							<TableCell className="table-head" />
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								S/N
							</TableCell>
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Name
							</TableCell>

							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Gender
							</TableCell>
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Sector item
							</TableCell>
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								LGA
							</TableCell>
						</TableRow>
					</TableHead>

					{equityDetails?.length > 0 &&
						equityDetails?.map((equity, idx) => {
							return (
								<TableBody key={idx} className="capitalize">
									<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
										<TableCell>
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() =>
													setOpen((current) => ({
														[idx]: !current[idx],
													}))
												}
											>
												{open[idx] ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)}
											</IconButton>
										</TableCell>
										<TableCell
											align="left"
											sx={{
												fontWeight: "500",
												letterSpacing: "2px",
											}}
										>
											{(idx += 1)}
										</TableCell>
										<TableCell component="th" scope="row" key={equity?.name}>
											{equity?.name}
										</TableCell>
										<TableCell align="left">{equity?.gender}</TableCell>
										<TableCell align="left">
											{equity?.subcategoryitem?.name}
										</TableCell>
										<TableCell align="left">{equity?.lga}</TableCell>
									</TableRow>

									<TableRow>
										<TableCell
											style={{ paddingBottom: 0, paddingTop: 0 }}
											colSpan={6}
										>
											<Collapse in={open[idx]} timeout="auto" unmountOnExit>
												<Box sx={{ backgroundColor: "white", padding: 2 }}>
													<img
														loading="lazy"
														src={equity?.passport || url}
														alt="logo"
														onError={(e) => (e.target.src = url)}
														className="w-48 h-48 border-4 border-gray-300 object-cover mx-auto rounded-full transition-transform hover:scale-105 duration-500 ease-in-out"
													/>

													<div className="flex justify-between my-3">
														<div className="mr-5 capitalize">
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Name:{" "}
																<span className="text-green-600">
																	{equity?.name === ""
																		? "No Data"
																		: equity?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Gender:{" "}
																<span className="text-green-600">
																	{equity?.gender === ""
																		? "No Data"
																		: equity?.gender}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Submission ID:{" "}
																<span className="text-green-600">
																	{equity?.submission_id === ""
																		? "No Data"
																		: equity?.submission_id}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																LGA:{" "}
																<span className="text-green-600">
																	{equity?.lga === "" ? "No Data" : equity?.lga}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Ward:{" "}
																<span className="text-green-600">
																	{equity?.ward === ""
																		? "No Data"
																		: equity?.ward}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Date of Birth:{" "}
																<span className="text-green-600">
																	{equity?.date_of_birth === ""
																		? "No Data"
																		: equity?.date_of_birth}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Marital Status:{" "}
																<span className="text-green-600">
																	{equity?.marital_status === ""
																		? "No Data"
																		: equity?.marital_status}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																NIN:{" "}
																<span className="text-green-600">
																	{equity?.nin_number === ""
																		? "No Data"
																		: equity?.nin_number}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Phone Number:{" "}
																<span className="text-green-600">
																	{equity?.phone_number === ""
																		? "No Data"
																		: equity?.phone_number}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Blood Group:{" "}
																<span className="text-green-600">
																	{equity?.blood_group === ""
																		? "No Data"
																		: equity?.blood_group}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Genotype:{" "}
																<span className="text-green-600">
																	{equity?.genotype === ""
																		? "No Data"
																		: equity?.genotype}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Email:{" "}
																<span className="text-green-600">
																	{equity?.email === ""
																		? "No Data"
																		: equity?.email}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																ID TYpe:{" "}
																<span className="text-green-600">
																	{equity?.id_type === ""
																		? "No Data"
																		: equity?.id_type}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Enrollment ID:{" "}
																<span className="text-green-600">
																	{equity?.enrollment_id === ""
																		? "No Data"
																		: equity?.enrollment_id}
																</span>
															</p>
														</div>
														<div className="ml-16">
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Facility Name:{" "}
																<span className="text-green-600">
																	{equity?.facility?.name === ""
																		? "No Data"
																		: equity?.facility?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Facility ID:{" "}
																<span className="text-green-600">
																	{equity?.facility?.id === ""
																		? "No Data"
																		: equity?.facility?.id}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																HMO Name:{" "}
																<span className="text-green-600">
																	{equity?.hmo?.name === ""
																		? "No Data"
																		: equity?.hmo?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																HMO ID:{" "}
																<span className="text-green-600">
																	{equity?.hmo?.id === ""
																		? "No Data"
																		: equity?.hmo?.id}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Home Address:{" "}
																<span className="text-green-600">
																	{equity?.home_address === ""
																		? "No Data"
																		: equity?.home_address}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Subcategory Name:{" "}
																<span className="text-green-600">
																	{equity?.subcategoryitem?.name === ""
																		? "No Data"
																		: equity?.subcategoryitem?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Subcategory ID:{" "}
																<span className="text-green-600">
																	{equity?.subcategoryitem?.id === ""
																		? "No Data"
																		: equity?.subcategoryitem?.id}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Settlement Type:{" "}
																<span className="text-green-600">
																	{equity?.settlement_type === ""
																		? "No Data"
																		: equity?.settlement_type}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Emergency Contact Address:{" "}
																<span className="text-green-600">
																	{equity?.emergency_contact_address === ""
																		? "No Data"
																		: equity?.emergency_contact_address}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Relationship With Emergency Contact:{" "}
																<span className="text-green-600">
																	{equity?.relationship_with_emergency_co === ""
																		? "No Data"
																		: equity?.relationship_with_emergency_co}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Next Of Kin:{" "}
																<span className="text-green-600">
																	{equity?.next_of_kin === ""
																		? "No Data"
																		: equity?.next_of_kin}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Next of Kin Phone Number:{" "}
																<span className="text-green-600">
																	{equity?.nok_phone === ""
																		? "No Data"
																		: equity?.nok_phone}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Submission Time:{" "}
																<span className="text-green-600">
																	{equity?.submission_time === ""
																		? "No Data"
																		: equity?.submission_time}
																</span>
															</p>
														</div>
													</div>
												</Box>
											</Collapse>
										</TableCell>
									</TableRow>
								</TableBody>
							);
						})}
				</Table>
				<p className="mx-auto p-2 text-xl text-green-500" align="center">
					{error}
				</p>
				<p className="mx-auto p-1 text-lg italics" align="center">
					{loader && "fetching details..."}
				</p>
				<button
					type="button"
					style={
						equityDetails.length === 0
							? { display: "none" }
							: { display: "block" }
					}
					className="text-blue-500 font-pop p-3"
					onClick={() => fetchData()}
				>
					{loading ? "loading..." : "see more"}
				</button>
			</TableContainer>
		</div>
	);
};

export default EquityDetails;
