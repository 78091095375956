import React, { useState, useCallback, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Axios from "axios";

function InformalDetails({ itemTitle }) {
	const [informalDetails, setInformalDetails] = useState([]);
	const [open, setOpen] = useState({});
	const [limit, setLimit] = useState(20);
	const [loading, setLoading] = useState(false);
	const [loader, setLoader] = useState(true);
	const [error, setError] = useState("");
	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const url =
		"https://png.pngtree.com/png-vector/20190114/ourmid/pngtree-vector-avatar-icon-png-image_313572.jpg";

	const getInformalDetails = useCallback(async () => {
		setLoader(true);
		await Axios.post(`${baseUrl}/${itemTitle}/records?limit=${limit}&skip=0`)
			.then((response) => {
				const details = response.data.Details;
				if (response.data.status === true && details.length > 0) {
					setInformalDetails(details);
				} else if (response.data.status === true && details.length === 0) {
					setError("Enrollments Ongoing...");
					setInformalDetails([]);
				} else {
					setError("Enrollments Ongoing...");
				}
				setLoader(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [itemTitle, limit, baseUrl]);

	useEffect(() => {
		getInformalDetails();
	}, [getInformalDetails]);

	const getInformalDetails2 = useCallback(async () => {
		setLoading(true);
		try {
			const fetch = await Axios.post(
				`${baseUrl}/${itemTitle}/records?limit=${limit}&skip=0`,
			);
			setLoading(false);
			const result = fetch.data.Details;
			return result;
		} catch (err) {
			console.log(err);
		}
	}, [limit, itemTitle, baseUrl]);

	const fetchData = async () => {
		const nextPage = await getInformalDetails2();
		setInformalDetails([...informalDetails, ...nextPage]);
		if (nextPage.length === 0) {
			return;
		}
		setLimit(limit + 10);
	};

	return (
		<div className="shadow-lg bg-white p-4 w-[60vw] mt-28 ml-[1rem] h-[90vh] overflow-auto rounded-sm">
			<h4 className="text-xl font-cant font-semibold tracking-wide my-5">
				Informal Sector Beneficiaries:{" "}
				<span style={{ color: "green" }}>{itemTitle}</span>{" "}
			</h4>

			<TableContainer component={Paper}>
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow className="bg-gray-500">
							<TableCell className="table-head" />
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								S/N
							</TableCell>
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Name
							</TableCell>

							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Gender
							</TableCell>
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								Sector item
							</TableCell>
							<TableCell
								align="left"
								sx={{
									color: "white",
									fontWeight: "500",
									letterSpacing: "2px",
								}}
							>
								LGA
							</TableCell>
						</TableRow>
					</TableHead>

					{informalDetails?.length > 0 &&
						informalDetails?.map((enroll, idx) => {
							return (
								<TableBody key={idx} className="capitalize">
									<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
										<TableCell>
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() =>
													setOpen((current) => ({
														[idx]: !current[idx],
													}))
												}
											>
												{open[idx] ? (
													<KeyboardArrowUpIcon />
												) : (
													<KeyboardArrowDownIcon />
												)}
											</IconButton>
										</TableCell>
										<TableCell component="th" scope="row">
											{(idx += 1)}
										</TableCell>
										<TableCell component="th" scope="row" key={enroll?.name}>
											{enroll?.name}
										</TableCell>
										<TableCell align="left">
											{enroll?.gender === undefined ||
											enroll?.gender === "" ||
											enroll?.gender === "undefined"
												? "No Data"
												: enroll?.gender}
										</TableCell>
										<TableCell align="left">
											{enroll?.subcategoryitem?.name}
										</TableCell>
										<TableCell align="left">{enroll?.lga}</TableCell>
									</TableRow>

									<TableRow>
										<TableCell
											style={{ paddingBottom: 0, paddingTop: 0 }}
											colSpan={6}
										>
											<Collapse in={open[idx]} timeout="auto" unmountOnExit>
												<Box sx={{ backgroundColor: "white", padding: 2 }}>
													<img
														src={enroll?.passport || url}
														alt="logo"
														onError={(e) => (e.target.src = url)}
														className="w-48 h-48 border-4 border-gray-300 object-cover mx-auto rounded-full transition-transform hover:scale-105 duration-500 ease-in-out"
													/>

													<div className="flex justify-between my-3 capitalize">
														<div className="mr-5">
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Name:{" "}
																<span className="text-green-600">
																	{enroll?.name === ""
																		? "No Data"
																		: enroll?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Gender:{" "}
																<span className="text-green-600">
																	{enroll?.gender === undefined ||
																	enroll?.gender === "" ||
																	enroll?.gender === "undefined"
																		? "No Data"
																		: enroll?.gender}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Submission ID:{" "}
																<span className="text-green-600">
																	{enroll?.submission_id === ""
																		? "No Data"
																		: enroll?.submission_id}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																LGA:{" "}
																<span className="text-green-600">
																	{enroll?.lga === "" ? "No Data" : enroll?.lga}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Ward:{" "}
																<span className="text-green-600">
																	{enroll?.ward === ""
																		? "No Data"
																		: enroll?.ward}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Date of Birth:{" "}
																<span className="text-green-600">
																	{enroll?.date_of_birth === ""
																		? "No Data"
																		: enroll?.date_of_birth}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Marital Status:{" "}
																<span className="text-green-600">
																	{enroll?.marital_status === ""
																		? "No Data"
																		: enroll?.marital_status}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																NIN:{" "}
																<span className="text-green-600">
																	{enroll?.nin_number === ""
																		? "No Data"
																		: enroll?.nin_number}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Phone Number:{" "}
																<span className="text-green-600">
																	{enroll?.phone_number === ""
																		? "No Data"
																		: enroll?.phone_number}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Blood Group:{" "}
																<span className="text-green-600">
																	{enroll?.blood_group === ""
																		? "No Data"
																		: enroll?.blood_group}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Genotype:{" "}
																<span className="text-green-600">
																	{enroll?.genotype === ""
																		? "No Data"
																		: enroll?.genotype}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Email:{" "}
																<span className="text-green-600">
																	{enroll?.email === ""
																		? "No Data"
																		: enroll?.email}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																ID TYpe:{" "}
																<span className="text-green-600">
																	{enroll?.id_type === ""
																		? "No Data"
																		: enroll?.id_type}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Enrollment ID:{" "}
																<span className="text-green-600">
																	{enroll?.enrollment_id === ""
																		? "No Data"
																		: enroll?.enrollment_id}
																</span>
															</p>
														</div>
														<div className="ml-16">
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Facility Name:{" "}
																<span className="text-green-600">
																	{enroll?.facility?.name === ""
																		? "No Data"
																		: enroll?.facility?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Facility ID:{" "}
																<span className="text-green-600">
																	{enroll?.facility?.id === ""
																		? "No Data"
																		: enroll?.facility?.id}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																HMO Name:{" "}
																<span className="text-green-600">
																	{enroll?.hmo?.name === ""
																		? "No Data"
																		: enroll?.hmo?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																HMO ID:{" "}
																<span className="text-green-600">
																	{enroll?.hmo?.id === ""
																		? "No Data"
																		: enroll?.hmo?.id}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Home Address:{" "}
																<span className="text-green-600">
																	{enroll?.home_address === ""
																		? "No Data"
																		: enroll?.home_address}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Subcategory Name:{" "}
																<span className="text-green-600">
																	{enroll?.subcategoryitem?.name === ""
																		? "No Data"
																		: enroll?.subcategoryitem?.name}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Subcategory ID:{" "}
																<span className="text-green-600">
																	{enroll?.subcategoryitem?.id === ""
																		? "No Data"
																		: enroll?.subcategoryitem?.id}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Settlement Type:{" "}
																<span className="text-green-600">
																	{enroll?.settlement_type === ""
																		? "No Data"
																		: enroll?.settlement_type}
																</span>
															</p>

															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Emergency Contact Address:{" "}
																<span className="text-green-600">
																	{enroll?.emergency_contact_address === ""
																		? "No Data"
																		: enroll?.emergency_contact_address}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Relationship With Emergency Contact:{" "}
																<span className="text-green-600">
																	{enroll?.relationship_with_emergency_co === ""
																		? "No Data"
																		: enroll?.relationship_with_emergency_co}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Next Of Kin:{" "}
																<span className="text-green-600">
																	{enroll?.next_of_kin === ""
																		? "No Data"
																		: enroll?.next_of_kin}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Next of Kin Phone Number:{" "}
																<span className="text-green-600">
																	{enroll?.nok_phone === ""
																		? "No Data"
																		: enroll?.nok_phone}
																</span>
															</p>
															<p className="mb-2 font-cant text-gray-500 font-semibold">
																Submission Time:{" "}
																<span className="text-green-600">
																	{enroll?.submission_time === ""
																		? "No Data"
																		: enroll?.submission_time}
																</span>
															</p>
														</div>
													</div>
												</Box>
											</Collapse>
										</TableCell>
									</TableRow>
								</TableBody>
							);
						})}
				</Table>
				<p className="mx-auto p-2 text-xl text-green-500" align="center">
					{error}
				</p>
				<p className="mx-auto p-1 text-lg italics" align="center">
					{loader && "fetching details..."}
				</p>
				<button
					type="button"
					style={
						informalDetails.length === 0
							? { display: "none" }
							: { display: "block" }
					}
					className="text-blue-500 font-pop p-3"
					onClick={() => fetchData()}
				>
					{loading ? "loading..." : "see more"}
				</button>
			</TableContainer>
		</div>
	);
}

export default InformalDetails;
