import React, { useEffect, useState, useCallback } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Link from "@mui/material/Link";

function ChangeFacility({ apiToken }) {
	const [user, setUser] = useState([]);
	const [enrollmentId, setEnrollmentId] = useState("");
	const [myLga, setMyLga] = useState([]);
	const [ward, setWard] = useState([]);
	const [facility, setFacility] = useState([]);
	const [selectedLga, setSelectedLga] = useState("");
	const [selectedWard, setSelectedWard] = useState("");
	const [selectedFacility, setSelectedFacility] = useState("");
	const [showOtherFacility, setOtherFacility] = useState(false);
	const [showPlateau, setPlateau] = useState(false);
	const [unlock, setUnlock] = useState("");
	const [verify, setVerify] = useState("Verify");
	const [submitText, setSubmitText] = useState("Submit");

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const getLocal = useCallback(async () => {
		try {
			const fetchLocal = await Axios.get(`${baseUrl}/lga/enrollment`);
			setMyLga(fetchLocal.data.data);
		} catch (err) {
			console.log(err);
		}
	}, [baseUrl]);

	const handleWard = (e) => {
		const result = e.target.value;
		setSelectedLga(result);
		Axios.get(`${baseUrl}/${result}/wards/enrollment`).then((response) => {
			setWard(response.data.data);
		});
	};

	const handleFac = useCallback(
		async (e) => {
			const impt = e.target.value;
			setSelectedWard(impt);
			try {
				const fetchItem = await Axios.post(
					`${baseUrl}/facility/${selectedLga}/${impt}`,
				);
				const subItem = fetchItem.data.data;
				setFacility(subItem);
			} catch (err) {
				console.log(err);
			}
		},
		[selectedLga, baseUrl],
	);

	useEffect(() => {
		getLocal();
	}, [getLocal]);

	//verify beneficiary
	const getDetails = useCallback(
		async (e) => {
			e.preventDefault();
			setVerify("Verifying...");
			try {
				const fetchData = await Axios.post(
					`${baseUrl}/verify/enrid/${enrollmentId}`,
				);
				const response = fetchData.data.data;
				if (response) {
					setUser(response);
					setVerify("Verify");
				} else {
					setUser(null);
					setVerify("Verify");
					toast.error(
						"Could not verify beneficiary! Please check the enrollment ID and try again",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			} catch (error) {
				console.log(error);
				setVerify("Verify");
				toast.error("Verification error! Please try again later.", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: false,
				});
			}
		},
		[enrollmentId, baseUrl],
	);

	//submitForm for other facility change
	const submitForm = (e) => {
		e.preventDefault();
		setSubmitText("Submitting...");
		const newFac = JSON.parse(selectedFacility);

		const params = JSON.stringify({
			enrollment_id: enrollmentId,
			new_facility: { id: newFac?.id, name: newFac?.name },
		});

		Axios({
			method: "POST",
			url: `${baseUrl}/change/facility`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${apiToken}`,
			},
			data: params,
		})
			.then((response) => {
				if (response.status === 200) {
					toast.success("Facility has been changed successfully!", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});
					setSubmitText("Submit");
					setSelectedLga("");
					setSelectedWard("");
					setSelectedFacility("");
				} else {
					setSubmitText("Submit");
					toast.error(
						"Authorization failed and Access denied. Try again later!",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			})
			.catch(function (error) {
				console.log(error);
				setSubmitText("Submit");
				toast.error(
					"Error encountered or Authorization failed.Try again later!",
					{
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					},
				);
			});
	};

	//submit form for Plateau Hospital
	const submitPlateauForm = (e) => {
		e.preventDefault();

		const params = JSON.stringify({
			enrollment_id: enrollmentId,
			new_facility: { id: 706512, name: "Plateau Hospital" },
		});

		Axios({
			method: "POST",
			url: `${baseUrl}/change/facility`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${apiToken}`,
			},
			data: params,
		})
			.then((response) => {
				if (response.status === 200) {
					toast.success("Facility has been updated successfully!", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});
					setPlateau(false);
					setUnlock("");
				} else {
					toast.error(
						"Authorization failed and Access denied. Try again later!",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			})
			.catch(function (error) {
				console.log(error);

				toast.error(
					"Error encountered or Authorization failed.Try again later!",
					{
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					},
				);
			});
	};

	const handlePlateau = () => {
		setPlateau((prevState) => !prevState);
		setOtherFacility(false);
	};

	const handleOtherFacility = () => {
		setOtherFacility((prevState) => !prevState);
		setPlateau(false);
	};

	return (
		<FormWrapper>
			<ToastContainer />
			<Paper className="paper">
				<div>
					<span className="main-title">CHANGE FACILITY</span>
					<form onSubmit={getDetails}>
						<div className="verify">
							<TextField
								className="box"
								required
								label="Enter Enrollment ID"
								sx={{
									width: "50ch",
									mt: 1,
								}}
								value={enrollmentId}
								onChange={(e) => setEnrollmentId(e.target.value)}
							/>
							<Button
								type="submit"
								disabled={!enrollmentId ? true : false}
								variant="contained"
								sx={{ ml: 3, width: "20ch", backgroundColor: "#00B4D8" }}
							>
								{verify}
							</Button>
						</div>

						<Divider sx={{ mb: 1 }} />
						<span className="sub-title">Beneficiary Details</span>
						<TextField
							className="box"
							placeholder="Name"
							InputProps={{
								readOnly: true,
							}}
							sx={{
								width: "30ch",
								mt: 2,
								ml: 8,
							}}
							value={user?.name || ""}
						/>
						<TextField
							className="box"
							placeholder="Subcategory item"
							InputProps={{
								readOnly: true,
							}}
							sx={{
								width: "30ch",
								mt: 2,
								ml: 8,
							}}
							value={user?.subcategoryitem?.name || ""}
						/>
						<TextField
							className="box"
							placeholder="Facility"
							InputProps={{
								readOnly: true,
							}}
							sx={{
								width: "30ch",
								mt: 2,
								ml: 8,
								mb: 2,
							}}
							value={user?.facility?.name || ""}
						/>
					</form>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						{sessionStorage.getItem("email_user") ===
						"kwandeexcel@gmail.com" ? (
							<Link
								variant="contained"
								sx={{
									mt: 2,
									width: "23ch",
									mb: 1,
									cursor: "pointer",
									textDecoration: "none",
								}}
								onClick={handlePlateau}
							>
								Plateau Hospital
							</Link>
						) : null}

						<Button
							fullWidth
							variant="contained"
							sx={{
								mt: 2,
								width: "23ch",
								mb: 1,
								backgroundColor: "#00B4D8",
							}}
							onClick={handleOtherFacility}
						>
							Update Facility
						</Button>
					</div>
				</div>

				<Divider sx={{ mb: 1 }} />

				{showPlateau && (
					<>
						<p className="sub-title2">Update Facility to Plateau Hospital</p>
						<form
							onSubmit={submitPlateauForm}
							style={{
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "space-around",
							}}
						>
							<div className="fac1">
								<TextField
									className="box"
									placeholder="Enrollment ID"
									value={enrollmentId}
									InputProps={{
										readOnly: true,
									}}
									sx={{
										width: "23ch",
										mt: 3,
										mr: 2,
									}}
								/>

								<TextField
									className="box"
									defaultValue="706512"
									InputProps={{
										readOnly: true,
									}}
									sx={{
										width: "23ch",
										mt: 3,
									}}
								/>
							</div>

							<div className="fac-1">
								<TextField
									className="box"
									required
									placeholder="Enter Access ID"
									value={unlock}
									onChange={(e) => setUnlock(e.target.value)}
									sx={{
										width: "25ch",
										mt: 2,
									}}
								/>

								<Button
									className="submit"
									type="submit"
									disabled={unlock !== "2580" || !enrollmentId ? true : false}
									fullWidth
									variant="contained"
									sx={{
										mt: 2,
										width: "25ch",
										ml: 2,
										backgroundColor: "#00B4D8",
									}}
								>
									Submit
								</Button>
							</div>
						</form>
					</>
				)}

				{showOtherFacility && (
					<>
						<p className="sub-title2">Select New Facility</p>
						<form
							onSubmit={submitForm}
							style={{
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "space-around",
							}}
						>
							<div className="fac1">
								<TextField
									className="box"
									name="lga"
									select
									required
									value={selectedLga}
									onChange={handleWard}
									SelectProps={{
										native: true,
									}}
									sx={{
										width: "23ch",
										mt: 3,
										mr: 2,
									}}
								>
									<option value="">Select LGA </option>
									{myLga?.map((curr) => (
										<option key={curr}>{curr}</option>
									))}
								</TextField>
								<TextField
									className="box"
									name="ward"
									select
									value={selectedWard}
									onChange={handleFac}
									SelectProps={{
										native: true,
									}}
									sx={{
										width: "23ch",
										mt: 3,
									}}
								>
									<option value="">Select Ward</option>
									{ward?.map((dep) => (
										<option key={dep}>{dep}</option>
									))}
								</TextField>
							</div>

							<div className="fac-1">
								<TextField
									className="box"
									name="facility_name"
									select
									required
									value={selectedFacility}
									onChange={(e) => setSelectedFacility(e.target.value)}
									SelectProps={{
										native: true,
									}}
									sx={{
										width: "25ch",
										mt: 2,
									}}
								>
									<option value="">Select Facility</option>
									{facility?.map((tul) => (
										<option key={tul.id} value={JSON.stringify(tul)}>
											{tul.name}
										</option>
									))}
								</TextField>

								<Button
									className="submit"
									type="submit"
									disabled={
										!selectedLga ||
										!selectedWard ||
										!selectedFacility ||
										!enrollmentId
											? true
											: false
									}
									fullWidth
									variant="contained"
									sx={{
										mt: 2,
										width: "25ch",
										ml: 2,
										backgroundColor: "#00B4D8",
									}}
								>
									{submitText}
								</Button>
							</div>
						</form>
					</>
				)}
			</Paper>
		</FormWrapper>
	);
}

export default ChangeFacility;

const FormWrapper = styled.div`
	margin: 0 3rem 0 17rem;
	.paper {
		padding: 1rem;
		margin-top: -2px;
		margin-left: -7rem;
		display: flex;
		flex-direction: column;
		height: auto;
		width: 470px;
	}
	.verify {
		display: flex;
		align-items: center;
		flex-grow: auto;
		margin: 1rem;
	}
	.main-title {
		background-color: #00c897;
		color: whitesmoke;
		padding: 10px 30px;
	}
	.sub-title {
		background-color: #00c897;
		color: whitesmoke;
		padding: 5px 30px;
	}
	.sub-title2 {
		background-color: #00c897;
		color: whitesmoke;
		padding: 5px 30px;
		margin-right: auto;
	}
	.fac-1 {
		display: flex;
		align-items: center;
	}
`;
