import React, { useState, useCallback, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import EditIcon from "@mui/icons-material/Edit";

const UpdateDetails = ({ apiToken }) => {
	const [enrollmentId, setEnrollmentId] = useState("");

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [nok, setNok] = useState("");
	const [dob, setDob] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [buttonText, setButtonText] = useState("Update");
	const [verifyButton, setVerifyButton] = useState("Verify");

	const baseUrl = process.env.REACT_APP_BASE_URL;

	//verify beneficiary
	const getDetails = useCallback(
		async (e) => {
			e.preventDefault();
			setVerifyButton("Verifying...");
			try {
				const fetchData = await Axios.post(
					`${baseUrl}/verify/enrid/${enrollmentId}`,
				);
				const response = fetchData.data.data;
				if (response) {
					setName(response?.name);
					setDob(response?.date_of_birth);
					setPhone(response?.phone_number);
					setNok(response?.next_of_kin);
					setVerifyButton("Verify");
				} else {
					setName(null);
					setDob(null);
					setPhone(null);
					setNok(null);
					setVerifyButton("Verify");
					toast.error(
						"Could not verify beneficiary! Please check the enrollment ID and try again",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			} catch (error) {
				console.log(error);
				setVerifyButton("Verify");
				setName(null);
				setDob(null);
				setPhone(null);
				setNok(null);
				toast.error("Verification error! Please try again later.", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: false,
				});
			}
		},
		[enrollmentId, baseUrl],
	);

	const submitForm = (e) => {
		e.preventDefault();
		setButtonText("Updating...");
		const params = JSON.stringify({
			enrollment_id: enrollmentId,
			updates: {
				name: name,
				date_of_birth: dob,
				phone_number: phone,
				next_of_kin: nok,
			},
		});

		Axios({
			method: "POST",
			url: `${baseUrl}/update/enrollments`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${apiToken}`,
			},
			data: params,
		})
			.then((response) => {
				if (response.status === 200) {
					toast.success("Details updated successfully!", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					});
					setName("");
					setDob("");
					setPhone("");
					setNok("");
					setButtonText("Update");
				} else {
					setButtonText("Update");
					toast.error(
						"Authorization failed and Access denied. Try again later!",
						{
							position: toast.POSITION.TOP_CENTER,
							autoClose: 5000,
							hideProgressBar: false,
						},
					);
				}
			})
			.catch(function (error) {
				console.log(error);
				setButtonText("Update");
				toast.error(
					"Error encountered or Authorization failed.Try again later!",
					{
						position: toast.POSITION.TOP_CENTER,
						autoClose: 5000,
						hideProgressBar: false,
					},
				);
			});
	};

	useEffect(() => {
		if (!enrollmentId || !phone || !nok || !dob) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [enrollmentId, phone, nok, dob]);

	return (
		<FormWrapper>
			<ToastContainer />
			<Paper className="paper">
				<div>
					<p className="main-title">Update Details</p>
					<form onSubmit={getDetails} className="verify">
						<TextField
							className="box"
							required
							label="Enter Enrollment ID"
							sx={{
								width: "50ch",
								mt: 1,
							}}
							value={enrollmentId}
							onChange={(e) => setEnrollmentId(e.target.value)}
						/>
						<Button
							type="submit"
							disabled={!enrollmentId ? true : false}
							variant="contained"
							sx={{ ml: 3, width: "20ch", backgroundColor: "#00B4D8" }}
						>
							{verifyButton}
						</Button>
					</form>
				</div>

				<Divider sx={{ mb: 1 }} />
				<p className="sub-title">Beneficiary Details</p>

				<form onSubmit={submitForm}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "5px",
						}}
					>
						<TextField
							className="box"
							placeholder="Name"
							required
							sx={{
								width: "28ch",
								mt: 2,
								mr: 2,
							}}
							value={name || ""}
							onChange={(e) => {
								setName(e.target.value);
							}}
						/>
						<TextField
							className="box"
							type="date"
							required
							placeholder="Date of Birth"
							sx={{
								width: "15ch",
								mt: 2,
							}}
							value={dob || ""}
							onChange={(e) => {
								setDob(e.target.value);
							}}
						/>
					</div>

					<TextField
						className="box"
						placeholder="Phone Number"
						required
						sx={{
							width: "20ch",
							mt: 2,
						}}
						value={phone || ""}
						onChange={(e) => {
							setPhone(e.target.value);
						}}
					/>
					<TextField
						className="box"
						placeholder="Next of Kin"
						required
						sx={{
							width: "25ch",
							mt: 2,
							ml: 2,
						}}
						value={nok || ""}
						onChange={(e) => {
							setNok(e.target.value);
						}}
					/>

					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							className="submit"
							disabled={isDisabled}
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								mt: 2,
								width: "20ch",
								mb: 2,
								backgroundColor: "#00B4D8",
							}}
						>
							{buttonText}
						</Button>
					</div>
				</form>
			</Paper>
		</FormWrapper>
	);
};

export default UpdateDetails;
const FormWrapper = styled.div`
	margin: 0 3rem 0 17rem;
	.paper {
		padding: 1rem;
		margin-top: -2px;
		margin-left: -7rem;
		display: flex;
		flex-direction: column;
		height: auto;
		width: 470px;
	}
	.verify {
		display: flex;
		align-items: center;
		flex-grow: auto;
		margin: 1rem;
	}

	.sub-title,
	.main-title,
	.sub-title2 {
		background-color: #00c897;
		color: whitesmoke;
		padding: 10px 0;
		text-align: center;
	}

	.fac-1 {
		display: flex;
		align-items: center;
	}
`;
