import React, { useCallback, useEffect, useState } from "react";
import Axios from "axios";
import styled from "styled-components";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddTaskIcon from "@mui/icons-material/AddTask";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import TransactionItem from "./TransactionItem";

export const Transaction = () => {
  const [balance, setBalance] = useState([]);
  const [book, setBook] = useState([]);
  const [earning, setEarning] = useState([]);
  const [withdraw, setWithdraw] = useState([]);

  //base_url
  const baseUrl = process.env.REACT_APP_SAFEPAY_URL;

  const getPay = useCallback(() => {
    const token = process.env.REACT_APP_TOKEN;
    Axios.get(`${baseUrl}/coralpay/pos/wallet/checkbalance/033`, {
      headers: {
        "content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => {
        setBalance(res.data.data.available_balance);
        setBook(res.data.data.book_balance);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [baseUrl]);

  const getEarning = useCallback(() => {
    const token = process.env.REACT_APP_TOKEN;
    Axios.get(`${baseUrl}/coralpay/pos/totalearning/merchantid/033`, {
      headers: {
        "content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => {
        setEarning(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [baseUrl]);

  const getWithdraw = useCallback(() => {
    const token = process.env.REACT_APP_TOKEN;
    Axios.get(`${baseUrl}/coralpay/pos/totalwithdrawal/merchantid/033`, {
      headers: {
        "content-Type": "application/json",
        "x-access-token": token,
      },
    })
      .then((res) => {
        setWithdraw(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [baseUrl]);

  useEffect(() => {
    getPay();
  }, [getPay]);

  useEffect(() => {
    getEarning();
  }, [getEarning]);

  useEffect(() => {
    getWithdraw();
  }, [getWithdraw]);

  return (
    <Wrapper>
      <div>
        <TransactionItem
          Icon={<AccountBalanceIcon />}
          title={"Available Balance"}
          data={balance}
          color={"#228B22"}
        />
        <TransactionItem
          Icon={<AddTaskIcon />}
          title={"Total Earnings"}
          data={earning}
          color={"#5F8575"}
        />
      </div>
      <div>
        <TransactionItem
          Icon={<MenuBookIcon />}
          title={"Book Balance"}
          data={book}
          color={"#454B1B"}
        />

        <TransactionItem
          Icon={<HowToVoteIcon />}
          title={"Total Withdrawal"}
          data={withdraw}
          color={"red"}
        />
      </div>
    </Wrapper>
  );
};

export default Transaction;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: auto;
  margin-left: 4rem;
  padding: 1rem;
`;
