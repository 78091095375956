import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import ReactApexCharts from "react-apexcharts";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import Axios from "axios";
import CountUp from "react-countup";
import Divider from "@mui/material/Divider";
//import { saveAs } from "file-saver";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

function Principal() {
	const [totalEnrollments, setTotalEnrollment] = useState(2840);
	const [totalFacility, setTotalFacility] = useState(400);
	const [enrollData, setEnrollData] = useState([]);
	const [facilityData, setFacilityData] = useState([]);
	const [labelEnroll, setLabelEnroll] = useState([]);
	const [labelFac, setLabelFac] = useState([]);

	const baseURL = process.env.REACT_APP_BASE_URL;

	const getTotalEnrollment = useCallback(async () => {
		try {
			const fetch = await Axios.get(`${baseURL}/enrollments/total`);
			setTotalEnrollment(fetch.data.data);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	const getDetails = useCallback(async () => {
		try {
			const fetch = await Axios.get(`${baseURL}/formal/details`);
			setTotalFacility(Object.values(fetch.data)[0]);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	const getFormal = useCallback(async () => {
		try {
			const fetch = await Axios.get(`${baseURL}/formal/months`);
			setEnrollData(fetch.data.enrollment.map((e) => e.y));
			setLabelEnroll(fetch.data.enrollment.map((e) => e.x));
			setLabelFac(fetch.data["selected facility"].map((c) => c.x));
			setFacilityData(fetch.data["selected facility"].map((c) => c.y));
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getDetails();
		getTotalEnrollment();
		getFormal();
		return () => {
			setTotalEnrollment(2840);
			setTotalFacility(400);
		};
	}, [getDetails, getTotalEnrollment, getFormal]);

	// //API call to download all facility data download
	// const handleClick = (e) => {
	//   e.preventDefault();
	//   Axios({
	//     url: "https://pshs3.herokuapp.com/facility/data/all",
	//     method: "POST",
	//     responseType: "blob",
	//     contentType:
	//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	//   })
	//     .then((res) => {
	//       const { headers, data } = res;
	//       saveAs(
	//         new Blob([data], { type: headers["content-type"] }),
	//         "GeneralData.xlsx"
	//       );
	//     })
	//     .catch((err) => {
	//       console.log(err);
	//     });
	// };

	// //API call for enrollments summary download...
	// const handleEnroll = (e) => {
	//   e.preventDefault();

	//   Axios({
	//     url: "https://pshs3.herokuapp.com/enrollment/summary",
	//     method: "POST",
	//     responseType: "blob",
	//     contentType:
	//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	//   })
	//     .then((res) => {
	//       const { headers, data } = res;
	//       saveAs(
	//         new Blob([data], { type: headers["content-type"] }),
	//         "Enrollment-summary.xlsx"
	//       );
	//     })
	//     .catch((err) => {
	//       console.log(err);
	//     });
	// };

	const config1 = {
		series: [
			{
				data: enrollData,
				type: "column",
				name: "Total Enrolled",
			},
		],
		options: {
			chart: {
				height: 10,

				stacked: false,
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: false,
						zoomin: false,
						zoomout: false,
						pan: false,
						reset: false,
					},
				},
			},

			dataLabels: {
				enabled: false,
			},
			noData: {
				text: "Loading...",
				align: "center",
				verticalAlign: "middle",
			},
			stroke: {
				width: [0, 2, 5],
				curve: "smooth",
			},

			plotOptions: {
				bar: {
					columnWidth: "25%",
				},
			},
			fill: {
				opacity: [0.85, 0.25, 1],
				gradient: {
					inverseColors: false,
					shade: "light",
					type: "vertical",
					opacityFrom: 0.85,
					opacityTo: 0.55,
					stops: [0, 100, 200, 200, 200],
				},
			},
			xaxis: {
				type: "date",
				categories: labelEnroll,
				title: {
					text: "",
				},
			},
			yaxis: {
				show: false,
				title: {
					text: "",
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				x: {
					format: "MMM yy",
				},
			},
		},
	};

	const config2 = {
		series: [
			{
				data: facilityData,
				type: "column",
				name: "Selected Facility",
			},
		],
		options: {
			chart: {
				height: 10,

				stacked: false,
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: false,
						zoomin: false,
						zoomout: false,
						pan: false,
						reset: false,
					},
				},
			},

			dataLabels: {
				enabled: false,
			},
			noData: {
				text: "Loading...",
				align: "center",
				verticalAlign: "middle",
			},
			stroke: {
				width: [0, 2, 5],
				curve: "smooth",
			},

			plotOptions: {
				bar: {
					columnWidth: "25%",
				},
			},
			fill: {
				opacity: [0.85, 0.25, 1],
				gradient: {
					inverseColors: false,
					shade: "light",
					type: "vertical",
					opacityFrom: 0.85,
					opacityTo: 0.55,
					stops: [0, 100, 200, 200, 200],
				},
			},
			xaxis: {
				type: "date",
				categories: labelFac,
				title: {
					text: "",
				},
			},

			yaxis: {
				show: false,
				title: {
					text: "",
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				x: {
					format: "MMM yy",
				},
			},
		},
	};

	return (
		<Wrapper>
			<section>
				<p className="text-lg text-gray-500 my-2 tracking-widest font-semibold">
					Enrollments
				</p>
				<div className="flex items-center justify-between">
					<div className="flex flex-col">
						<CountUp
							style={{
								fontSize: "30px",
								fontWeight: "800",
								color: "forestgreen",
								letterSpacing: "3px",
							}}
							start={2843}
							end={totalEnrollments}
							duration={4}
							separator={","}
							startOnMount={true}
						/>
						<p className="text-sm text-gray-500 border-b-2 border-b-[green] py-2">
							Total Enrolled
						</p>
					</div>
					<div className="w-[15vw]">
						<ReactApexCharts
							options={config1.options}
							series={config1.series}
							type="bar"
						/>
					</div>
				</div>
				<Divider />
				<div className="flex items-center justify-between mt-3">
					<div className="flex flex-col">
						<CountUp
							style={{
								fontSize: "25px",
								fontWeight: "800",
								color: "blue",
								letterSpacing: "3px",
							}}
							start={400}
							end={totalFacility}
							duration={4}
							separator={","}
							startOnMount={true}
						/>

						<p className="text-sm text-gray-500 border-b-2 border-b-[blue] py-2">
							Selected Facility
						</p>
					</div>
					<div className="w-[15vw]">
						<ReactApexCharts
							options={config2.options}
							series={config2.series}
							type="bar"
						/>
					</div>
				</div>
			</section>
			<Divider />
			<section className="my-3 flex items-center justify-between">
				<div className="flex flex-col">
					<div className="">
						<FiberManualRecordOutlinedIcon
							style={{ fontSize: "16px" }}
							className="text-blue-500"
						/>{" "}
						<span className="text-sm font-semibold border-b-2 border-b-[blue] text-gray-500">
							Selected Facility
						</span>
					</div>

					<Button
						type="primary"
						shape="round"
						className="flex items-center bg-blue-500 my-4 text-white"
						//onClick={handleClick}
						icon={<DownloadOutlined />}
						size="large"
					>
						Facility Data
					</Button>

					{/* <button
						//onClick={handleClick}
						type="button"
						className="w-[10vw] mt-4 bg-transparent text-sm hover:bg-blue-500 text-blue-500 font-semibold hover:text-white p-2 border border-blue-500 hover:border-transparent rounded"
					>
						<span>
							<DownloadIcon style={{ fontSize: "18px" }} />
						</span>
						Facility Data
					</button> */}
				</div>

				<div className="flex flex-col">
					<div className="">
						<FiberManualRecordOutlinedIcon
							style={{ fontSize: "16px" }}
							className="text-green-500"
						/>
						<span className="text-sm font-semibold border-b-2 border-b-[green] text-gray-500">
							Total Enrolled
						</span>
					</div>

					<Button
						type="success"
						shape="round"
						className="flex items-center bg-green-500 my-4 text-white"
						//onClick={handleEnroll}
						icon={<DownloadOutlined />}
						size="large"
					>
						Enrollment Data
					</Button>
				</div>
			</section>
		</Wrapper>
	);
}

export default Principal;

const Wrapper = styled.div`
	font-family: "Poppins";
	display: flex;
	flex-direction: column;
	flex: auto;
`;
