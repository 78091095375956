import React from "react";
import BankDetails from "./BankDetails";

function Bank() {
  return (
    <div>
      <BankDetails />
    </div>
  );
}

export default Bank;
