import React, { useState, useCallback, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Axios from "axios";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import FormalDependents from "./FormalDependents";

const url =
	"https://png.pngtree.com/png-vector/20190114/ourmid/pngtree-vector-avatar-icon-png-image_313572.jpg";

const FormalDetails = ({ itemTitle, apiToken }) => {
	const [formalDetails, setFormalDetails] = useState([]);
	const [open, setOpen] = useState({});
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(50);
	const [showEdit, setShowEdit] = useState(false);
	const [error, setError] = useState("");
	const [loader, setLoader] = useState(true);

	//base_url
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const getFormalDetails = useCallback(async () => {
		setLoader(true);
		await Axios.post(`${baseUrl}/${itemTitle}/records?limit=${limit}&skip=0`)
			.then((response) => {
				let details = response.data.Details;

				if (response.data.status === true && details.length > 0) {
					setFormalDetails(details);
				} else if (response.data.status === true && details.length === 0) {
					setError("Enrollments Ongoing...");
					setFormalDetails([]);
				} else {
					setError("Enrollments Ongoing...");
				}
				setLoader(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [itemTitle, limit, baseUrl]);

	useEffect(() => {
		getFormalDetails();
	}, [getFormalDetails]);

	//infintine loadmore action
	const getFormalDetails2 = useCallback(async () => {
		setLoading(true);
		try {
			const fetch = await Axios.post(
				`${baseUrl}/${itemTitle}/records?limit=${limit}&skip=0`,
			);
			setLoading(false);
			const result = fetch.data.Details;
			return result;
		} catch (err) {
			console.log(err);
		}
	}, [limit, itemTitle, baseUrl]);

	const fetchData = async () => {
		const nextPage = await getFormalDetails2();
		setFormalDetails([...formalDetails, ...nextPage]);
		if (nextPage.length === 0) {
			return;
		}
		setLimit(limit + 50);
	};

	const handleClick = () => {
		setShowEdit(false);
	};

	return (
		<div className="shadow-lg bg-white p-4 w-[55vw] mt-28 h-[90vh] ml-[1rem] overflow-auto rounded-sm">
			<h4 className="text-xl font-cant font-semibold tracking-wide my-5">
				Formal Sector Beneficiaries:{" "}
				<span style={{ color: "green" }}>{itemTitle}</span>{" "}
			</h4>

			<Table aria-label="collapsible table">
				<TableHead>
					<TableRow className="bg-gray-500">
						<TableCell className="table-head" />
						<TableCell
							align="left"
							sx={{
								color: "white",
								fontWeight: "500",
								letterSpacing: "2px",
							}}
						>
							S/N
						</TableCell>
						<TableCell
							align="left"
							sx={{
								color: "white",
								fontWeight: "500",
								letterSpacing: "2px",
							}}
						>
							Name
						</TableCell>
						<TableCell
							align="left"
							sx={{
								color: "white",
								fontWeight: "500",
								letterSpacing: "2px",
							}}
						>
							Gender
						</TableCell>
						<TableCell
							align="left"
							sx={{
								color: "white",
								fontWeight: "500",
								letterSpacing: "2px",
							}}
						>
							Sector item
						</TableCell>
						<TableCell
							align="left"
							sx={{
								color: "white",
								fontWeight: "500",
								letterSpacing: "2px",
							}}
						>
							LGA
						</TableCell>
					</TableRow>
				</TableHead>

				{formalDetails?.length > 0 &&
					formalDetails?.map((record, idx) => {
						return (
							<TableBody key={idx}>
								<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
									<TableCell onClick={handleClick}>
										<IconButton
											aria-label="expand row"
											size="small"
											onClick={() =>
												setOpen((current) => ({
													[idx]: !current[idx],
												}))
											}
										>
											{open[idx] ? (
												<KeyboardArrowUpIcon />
											) : (
												<KeyboardArrowDownIcon />
											)}
										</IconButton>
									</TableCell>
									<TableCell align="left">{(idx += 1)}</TableCell>
									<TableCell component="th" scope="row" key={record?.name}>
										{record?.name}
									</TableCell>
									<TableCell align="left">
										{record?.gender === "Nan" ? "No Data" : record?.gender}
									</TableCell>
									<TableCell align="left">
										{record?.subcategoryitem?.name}
									</TableCell>
									<TableCell align="left">{record?.lga}</TableCell>
								</TableRow>

								<TableRow>
									<TableCell
										style={{ paddingBottom: 0, paddingTop: 0 }}
										colSpan={6}
									>
										<Collapse in={open[idx]} timeout={1000} unmountOnExit>
											<Box sx={{ backgroundColor: "white", padding: 2 }}>
												<img
													src={record?.passport || url}
													alt="logo"
													onError={(e) => (e.target.src = url)}
													className="w-48 h-48 border-4 border-gray-300 object-cover mx-auto rounded-full transition-transform hover:scale-105 duration-500 ease-in-out"
												/>

												<div className="flex justify-between my-3">
													<div className="mr-5">
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Name:{" "}
															<span className="text-green-600">
																{record?.name === "" ? "No Data" : record?.name}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Gender:{" "}
															<span className="text-green-600">
																{record?.gender === ""
																	? "No Data"
																	: record?.gender}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Submission ID:{" "}
															<span className="text-green-600">
																{record?.submission_id === ""
																	? "No Data"
																	: record?.submission_id}
															</span>
														</p>

														<p className="mb-2 font-cant text-gray-500 font-semibold">
															LGA:{" "}
															<span className="text-green-600">
																{record?.lga === "" ? "No Data" : record?.lga}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Ward:{" "}
															<span className="text-green-600">
																{record?.ward === "" ? "No Data" : record?.ward}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Date of Birth:{" "}
															<span className="text-green-600">
																{record?.date_of_birth === ""
																	? "No Data"
																	: record?.date_of_birth}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Marital Status:{" "}
															<span className="text-green-600">
																{record?.marital_status === ""
																	? "No Data"
																	: record?.marital_status}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															NIN:{" "}
															<span className="text-green-600">
																{record?.nin_number === ""
																	? "No Data"
																	: record?.nin_number}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Phone Number:{" "}
															<span className="text-green-600">
																{record?.phone_number === ""
																	? "No Data"
																	: record?.phone_number}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Blood Group:{" "}
															<span className="text-green-600">
																{record?.blood_group === ""
																	? "No Data"
																	: record?.blood_group}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Genotype:{" "}
															<span className="text-green-600">
																{record?.genotype === ""
																	? "No Data"
																	: record?.genotype}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Email:{" "}
															<span className="text-green-600">
																{record?.email === ""
																	? "No Data"
																	: record?.email}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															ID TYpe:{" "}
															<span className="text-green-600">
																{record?.id_type === ""
																	? "No Data"
																	: record?.id_type}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Submission Time:{" "}
															<span className="text-green-600">
																{record?.submission_time === ""
																	? "No Data"
																	: record?.submission_time}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Enrollment ID:{" "}
															<span className="text-green-600">
																{record?.enrollment_id === ""
																	? "No Data"
																	: record?.enrollment_id}
															</span>
														</p>
													</div>
													<div className="ml-16">
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Facility Name:{" "}
															<span className="text-green-600">
																{record?.facility?.name === ""
																	? "No Data"
																	: record?.facility?.name}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Facility ID:{" "}
															<span className="text-green-600">
																{record?.facility?.id === ""
																	? "No Data"
																	: record?.facility?.id}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															HMO Name:{" "}
															<span className="text-green-600">
																{record?.hmo?.name === ""
																	? "No Data"
																	: record?.hmo?.name}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															HMO ID:{" "}
															<span className="text-green-600">
																{record?.hmo?.id === ""
																	? "No Data"
																	: record?.hmo?.id}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Home Address:{" "}
															<span className="text-green-600">
																{record?.home_address === ""
																	? "No Data"
																	: record?.home_address}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Subcategory Name:{" "}
															<span className="text-green-600">
																{record?.subcategoryitem?.name === ""
																	? "No Data"
																	: record?.subcategoryitem?.name}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Subcategory ID:{" "}
															<span className="text-green-600">
																{record?.subcategoryitem?.id === ""
																	? "No Data"
																	: record?.subcategoryitem?.id}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Number of Dependents:{" "}
															<span className="text-green-600">
																{record?.number_dep === ""
																	? "No Data"
																	: record?.number_dep}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Settlement Type:{" "}
															<span className="text-green-600">
																{record?.settlement_type === ""
																	? "No Data"
																	: record?.settlement_type}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Emergency Contact:{" "}
															<span className="text-green-600">
																{record?.emergency_contact === ""
																	? "No Data"
																	: record?.emergency_contact}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Emergency Contact Address:{" "}
															<span className="text-green-600">
																{record?.emergency_contact_address === ""
																	? "No Data"
																	: record?.emergency_contact_address}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Relationship With Emergency Contact:{" "}
															<span className="text-green-600">
																{record?.relationship_with_emergency_co === ""
																	? "No Data"
																	: record?.relationship_with_emergency_co}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Next Of Kin:{" "}
															<span className="text-green-600">
																{record?.next_of_kin === ""
																	? "No Data"
																	: record?.next_of_kin}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Next of Kin Phone Number:{" "}
															<span className="text-green-600">
																{record?.nok_phone === ""
																	? "No Data"
																	: record?.nok_phone}
															</span>
														</p>
														<p className="mb-2 font-cant text-gray-500 font-semibold">
															Status:{" "}
															<span className="text-green-600">
																{record?.status === ""
																	? "No Data"
																	: record?.status}
															</span>
														</p>
													</div>
												</div>

												<Button
													fullWidth
													variant="contained"
													disabled={
														record?.number_dep === 0 ||
														record?.number_dep === "" ||
														record?.number_dep === undefined
															? true
															: false
													}
													sx={{
														mt: 2,
														width: "23ch",
														mb: 1,
														backgroundColor: "#00B4D8",
													}}
													onClick={() => setShowEdit(!showEdit)}
												>
													{record?.number_dep === 0 ||
													record?.number_dep === "" ||
													record?.number_dep === undefined
														? "No Dependents"
														: "Dependents Menu"}
												</Button>
												<Divider />
												{showEdit && (
													<FormalDependents
														enrollmentID={record?.enrollment_id}
														apiToken={apiToken}
													/>
												)}
											</Box>
										</Collapse>
									</TableCell>
								</TableRow>
							</TableBody>
						);
					})}
			</Table>
			<p className="mx-auto p-2 text-xl text-green-500" align="center">
				{error}
			</p>
			<p className="mx-auto p-1 text-lg italics" align="center">
				{loader && "fetching details..."}
			</p>
			<button
				type="button"
				style={
					formalDetails.length === 0
						? { display: "none" }
						: { display: "block" }
				}
				className="text-blue-500 font-pop p-3"
				onClick={() => fetchData()}
			>
				{loading ? "loading..." : "see more"}
			</button>
		</div>
	);
};

export default FormalDetails;
