import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Axios from "axios";
import { myTitle } from "../../redux/TitleSlice";
import { useSelector } from "react-redux";

function Encounter() {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const title = useSelector(myTitle);

	const [open, setOpen] = useState({});
	const [details, setDetails] = useState([]);

	const getDetails = useCallback(async () => {
		try {
			const fetch = await Axios.get(`${baseURL}/all/encounter`);

			const output = fetch.data.data;
			setDetails(output);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getDetails();
	}, [getDetails]);

	return (
		<Wrapper>
			{title !== "ENCOUNTERS" ? (
				""
			) : (
				<>
					<div className="mail-title">
						<div>
							<h3 className="font-cant font-semibold tracking-wider text-green-600 text-xl">
								Facility Encounters
							</h3>
						</div>
					</div>
					<Paper sx={{ width: "100%", overflow: "hidden" }}>
						<TableContainer component={Paper}>
							<Table aria-label="collapsible table">
								<TableHead>
									<TableRow>
										<TableCell className="table-head" />
										<TableCell align="left" className="table-head">
											Enrollment ID
										</TableCell>
										<TableCell align="left" className="table-head">
											Encounter
										</TableCell>
										<TableCell align="left" className="table-head">
											Facility Code
										</TableCell>
										<TableCell align="left" className="table-head">
											Encounter Date & Time
										</TableCell>
									</TableRow>
								</TableHead>

								{details?.map((detail, id) => {
									return (
										<TableBody key={`${details}-${id}`}>
											<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
												<TableCell>
													<IconButton
														aria-label="expand row"
														size="small"
														onClick={() =>
															setOpen((current) => ({
																...current,
																[id]: !current[id],
															}))
														}
													>
														{open[id] ? (
															<KeyboardArrowUpIcon />
														) : (
															<KeyboardArrowDownIcon />
														)}
													</IconButton>
												</TableCell>
												<TableCell component="th" scope="row">
													{detail.enrollment_id}
												</TableCell>
												<TableCell align="left">{detail?.code}</TableCell>
												<TableCell align="left">
													{detail.facility?.name}
												</TableCell>
												<TableCell align="left">{detail?.date}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell
													style={{ paddingBottom: 0, paddingTop: 0 }}
													colSpan={6}
												>
													<Collapse in={open[id]} timeout="auto" unmountOnExit>
														<Box sx={{ margin: 1 }}>
															<Typography
																variant="h6"
																gutterBottom
																component="div"
															>
																Details
															</Typography>
															<div className="enc-profile">
																<table>
																	<thead></thead>
																	<tbody>
																		<tr>
																			<td className="head">
																				<b>Type:</b>
																			</td>
																			<td className="val">
																				{detail.encounter}
																			</td>
																		</tr>
																		<tr>
																			<td className="head">
																				<b> Enrollment ID:</b>
																			</td>
																			<td className="val">
																				{detail.enrollment_id}
																			</td>
																		</tr>
																		<tr>
																			<td className="head">
																				<b>Encounter:</b>
																			</td>
																			<td className="val">{detail?.code}</td>
																		</tr>
																		<tr>
																			<td className="head">
																				<b>Facility Code:</b>
																			</td>
																			<td className="val">
																				{detail?.facility?.name}
																			</td>
																		</tr>
																		<tr>
																			<td className="head">
																				<b>Encounter Date & Time:</b>
																			</td>
																			<td className="val">{detail?.date}</td>
																		</tr>

																		{/* <tr>
																			<td className="head">
																				<b>Primary Encounter:</b>
																			</td>
																			<td className="val">
																				{detail.primary_encounter}
																			</td>
																		</tr> */}
																	</tbody>
																</table>
															</div>
														</Box>
													</Collapse>
												</TableCell>
											</TableRow>
										</TableBody>
									);
								})}
							</Table>
						</TableContainer>
					</Paper>
				</>
			)}
		</Wrapper>
	);
}

export default Encounter;

const Wrapper = styled.div`
	width: 650px;
	max-width: 650px;
	padding: 20px;
	margin: -0.5rem 0 0.2rem 0;
	flex: 1;
	height: 100vh;
	font-family: "Poppins";
	background-color: white;
	overflow: auto;
	.mail-title > h3 {
		margin-bottom: -0.2rem;
	}
	.mail-title {
		margin-bottom: 1rem;
		display: flex;
		padding-bottom: 1rem;
		align-items: center;
		justify-content: space-between;
	}
	.table-head {
		font-weight: 700;
		background-color: #dcdcdc;
	}
	.enc-profile {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.enc-profile img {
		width: 10rem;
		height: 10rem;
		object-fit: cover;
		border-radius: 50%;
		display: block;
		margin: 0 2rem 0 1rem;
	}
	.enc-profile > table {
		margin: 1rem auto;
		font-size: 12px;
	}
	.head {
		padding-bottom: 1rem;
	}
	.val {
		padding-left: 2rem;
		padding-bottom: 1rem;
		color: green;
		font-weight: 600;
	}
	@media (max-width: 500px) {
		width: 500px;
		margin-left: 8rem;
		overflow-x: scroll;
		margin-top: -8rem;
		flex: 1 1 auto;
		border-radius: 15px;
		white-space: nowrap;

		.mail-title {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			justify-items: center;
			margin-bottom: 1rem;
		}
		.enc-wrapper {
			display: block;
		}
		.enc-table {
			padding-bottom: 2rem;
			border-right: none;
			border-bottom: 2px solid grey;
		}
		.enc-profile {
			display: flex;
			flex-direction: column;
		}
		.enc-profile ul {
			line-height: 2rem;
		}
	}
`;
