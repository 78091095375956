import React, { useState, useEffect, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import Axios from "axios";
import Paper from "@mui/material/Paper";
import { FaCheckCircle } from "react-icons/fa";
import { BsDashCircleFill } from "react-icons/bs";
import ProgressBar from "./ProgressBar";

export default function HMO() {
	const [hmoProgress, setHmoProgress] = useState([]);

	const baseURL = process.env.REACT_APP_BASE_URL;

	const getHmo = useCallback(async () => {
		try {
			const fetchData = await Axios.get(`${baseURL}/hmo/progress`);
			setHmoProgress(Object.entries(fetchData.data.data));
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getHmo();
	}, [getHmo]);

	return (
		<Wrapper>
			<h3 className="my-6 ml-4 text-2xl font-semibold font-cant ">
				Approved Health Maintenance Organizations
			</h3>
			<TableContainer component={Paper} className="main">
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell className="headCell">S/N</TableCell>

							<TableCell align="left" className="headCell">
								HMO
							</TableCell>

							<TableCell align="left" className="headCell">
								Enrollment Progress&nbsp;(%)
							</TableCell>
							<TableCell align="right" className="headCell"></TableCell>
							<TableCell align="left" className="headCell">
								Status
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{hmoProgress?.map((item, index) => (
							<TableRow
								sx={{
									"&:last-child td, &:last-child th": { border: 0 },
								}}
								key={index}
							>
								<TableCell>{(index += 1)}</TableCell>
								<TableCell align="left">{item[0]}</TableCell>

								<TableCell align="left">
									<ProgressBar
										bgcolor={
											item[1] > 65
												? "green"
												: parseInt(item[1]) > 50 && parseInt(item[1]) < 65
												? "orange"
												: "red"
										}
										completed={item[1]}
									/>
								</TableCell>
								<TableCell align="left">
									{" "}
									{parseInt(item[1])}%&nbsp;Progress
								</TableCell>
								<TableCell align="right">
									{parseInt(item[1]) > 65 ? (
										<FaCheckCircle className="icon" />
									) : (
										<BsDashCircleFill className="icon1" />
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	.headCell {
		font-size: 16px;
		font-weight: 700;
		background-color: lightgrey;
	}
	.icon {
		font-size: 15px;
		color: green;
	}
	.icon1 {
		font-size: 15px;
		color: #c4b454;
	}
	.icon2 {
		font-size: 17px;
		color: red;
	}
	h3 {
		text-align: left;
		padding: 5px;
	}
	::-webkit-progress-value {
		background-colour: white;
	}

	@keyframes progress {
		from {
			width: 0;
		}
	}

	@media (max-width: 500px) {
		margin-left: -2rem;
		overflow: scroll;
		flex: 1 1 auto;
		border-radius: 15px;
		white-space: nowrap;
	}
`;
