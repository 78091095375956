import React from "react";
import Paper from "@mui/material/Paper";
import styled from "styled-components";

function BankDetails() {
  return (
    <Wrapper>
      <Paper className="paper">
        <h2>BANK DETAILS</h2>
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td>Account Name:</td>
              <td className="data">PLATEAU STATE CONTRIBUTORY HEALTH CARE MANAGEMENT AGENCY ACCOUNT</td>
            </tr>
            <tr>
              <td>Account Number:</td>
              <td className="data">4060014118</td>
            </tr>
            <tr>
              <td>Bank ID:</td>
              <td className="data">076</td>
            </tr>
            <tr>
              <td>Bank Name:</td>
              <td className="data">Skye Bank </td>
            </tr>
            <tr>
              <td>Name of Business:</td>
              <td className="data">PLASCHEMA</td>
            </tr>
          </tbody>
        </table>
      </Paper>
    </Wrapper>
  );
}

export default BankDetails;

const Wrapper = styled.div`
  height: auto;
  
  margin-left: 6rem;
  padding: 1rem;

  .paper {
    display:flex;
    flex-direction: column;
    flex: auto;
    width: auto;
    padding: 2rem;
    line-height: 2;
    background-color: #eeedde;
  }
  .paper > h2 {
    text-align: center;
    background-color: whitesmoke;
  }
  .data {
    color: green;
    font-size: 13px;
    font-weight: 600;
  }
`;
