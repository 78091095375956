import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { myTitle } from "../../redux/TitleSlice";

const ITEM_HEIGHT = 40;

const activeEnrollments = [
  {
    icon: <HowToRegRoundedIcon />,
    text: "Approved",
    link: "/enrollments",
  },
  {
    icon: <MoreHorizIcon />,
    text: "Pending",
    link: "/pendingenroll",
  },
];

const subscriptions = [
  {
    icon: <ArrowForwardRoundedIcon />,
    text: "Enrollee Subscriptions",
    
  },
];
const encounters = [
  {
    icon: <ArrowForwardRoundedIcon />,
    text: "Facility Encounters",
  },
];

function Mailboxlist() {
  const title = useSelector(myTitle);
  const navigate = useNavigate();
  const [visibility, setVisibility] = useState();
  const [screenWidth, setScreenWidth] = useState(0);
  const [visibility2, setVisibility2] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const trackScreenWidth = () => {
    const width = window.innerWidth;
    setScreenWidth(width);
    if (width < 501) {
      setVisibility(true);
      setVisibility2(false);
    } else if (width > 500) {
      setVisibility(false);
      setVisibility2(true);
    }
  };

  useEffect(() => {
    trackScreenWidth();
    window.addEventListener("resize", trackScreenWidth);
    return () => window.removeEventListener("resize", trackScreenWidth);
  }, [screenWidth]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <NavWrapper>
        {visibility && (
          <>
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon className="menu-icon" />
              </IconButton>
              <div className="menu">
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "25ch",
                      backgroundColor: "#e5e5e5",
                      color: "green",
                    },
                  }}
                  elevation={2}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Button
                    className="sidebar_title"
                    startIcon={<AddIcon fontSize="large" />}
                  >
                    {title}
                  </Button>

                  <Divider />

                  {title !== "ENROLLMENTS"
                    ? ""
                    : activeEnrollments.map((enroll, id1) => {
                        return (
                          <NavLink
                          exact="true"
                            to={enroll.link}
                            key={enroll}
                            className="box"
                          >
                            <MenuItem
                              className="sidebarOption"
                              onClick={handleClose}
                            >
                              {enroll.icon}
                              {enroll.text}
                            </MenuItem>
                          </NavLink>
                        );
                      })}

                  {title !== "SUBSCRIPTIONS"
                    ? ""
                    : subscriptions.map((sub, id2) => {
                        return (
                          <div
                            onClick={() => navigate("/subscription")} //edit
                            className="box"
                            key={sub}
                            
                          >
                            <MenuItem
                              className="sidebarOption"
                              onClick={handleClose}
                            >
                              {sub.icon}
                              {sub.text}
                            </MenuItem>
                          </div>
                        );
                      })}
                  {title !== "ENCOUNTERS"
                    ? ""
                    : encounters.map((encounter, id3) => {
                        return (
                          <NavLink
                          exact="true"
                            to="/encounter"
                            className="box"
                            key={encounter}
                          >
                            <MenuItem
                              className="sidebarOption"
                              onClick={handleClose}
                            >
                              {encounter.icon}
                              {encounter.text}
                            </MenuItem>
                          </NavLink>
                        );
                      })}
                  {title !== "FACILITIES"
                    ? ""
                    : activeEnrollments.map((enroll, id4) => {
                        return (
                          <NavLink
                          exact="true"
                            to="/facility"
                            className="box"
                            key={enroll}
                          >
                            <MenuItem
                              className="sidebarOption"
                              onClick={handleClose}
                            >
                              {enroll.icon}
                              {enroll.text}
                            </MenuItem>
                          </NavLink>
                        );
                      })}
                      
                </Menu>
              </div>
            </div>
          </>
        )}
      </NavWrapper>

      {visibility2 && (
        <MainWrapper>
          <div className="sidebar">
            <Button
              startIcon={<AddIcon fontSize="large" />}
              className="sidebar_compose"
              disabled
            >
              {title}
            </Button>
            <div className="title">
              <h4>Folders</h4>
              <Divider />
            </div>

           

            {title !== "SUBSCRIPTIONS"
              ? ""
              : subscriptions.map((item2, index2) => {
                  return (
                    <NavLink
                      exact="true"
                      to="/subscription/subscriptiondetails" 
                      className="box"
                      key={index2}
                    >
                      <MenuItem className="sidebarOption">
                        {item2.icon}
                        {item2.text}
                      </MenuItem>
                    </NavLink>
                  );
                })}
            {title !== "ENCOUNTERS"
              ? ""
              : encounters.map((item3, index3) => {
                  return (
                    <NavLink exact="true" to="/encounter/encounterdetails" className="box" key={index3}>
                      <MenuItem className="sidebarOption">
                        {item3.icon}
                        {item3.text}
                      </MenuItem>
                    </NavLink>
                  );
                })}
            {title !== "FACILITIES"
              ? ""
              : activeEnrollments.map((item4, index4) => {
                  return (
                    <NavLink exact="true" to="/facility/facilitydetails" className="box" key={index4}>
                      <MenuItem className="sidebarOption">
                        {item4.icon}
                        {item4.text}
                      </MenuItem>
                    </NavLink>
                  );
                })}
                
          </div>
        </MainWrapper>
      )}
    </Wrapper>
  );
}

export default Mailboxlist;
const Wrapper = styled.div`
  width: 300px;
  height: 60vh;
  max-width: 300px;
  margin-top: -27rem;
  margin-bottom: -8rem;
`;

const MainWrapper = styled.div`
  .title > h4 {
    font-family: "Poppins";
  }
  .title {
    margin-bottom: 1rem;
  }
  .sidebar {
    flex: 1;
    max-width: 300px;
    padding-right: 20px;
  }
  .sidebarOption {
    display: flex;
    flex: 1;
    align-items: center;
    height: 40px;
    padding: 30px 5px;
    color: #818589;
    font-weight: 800;
    cursor: pointer;
    font-family: "Poppins";
    text-decoration: none;
  }
  .sidebarOption:hover {
    border-radius: 0 120px 120px 0;
    font-weight: 800 !important;
    border-left: 3px solid forestgreen;
    color: black;
  }
  .sidebarOption > .MuiSvgIcon-root {
    padding: 5px;
  }
  .box {
    text-decoration: none;
  }
  .sidebar_compose {
    width: 200px;
    max-width: 200px;
    margin: 8rem 0 1rem 2rem !important;
    text-transform: capitalize !important;
    color: whitesmoke !important;
    padding: 15px 30px !important;
    font-weight: 600 !important;
    background-color: forestgreen;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.75);
  }
`;
const NavWrapper = styled.div`
  margin: 3rem -4rem 20rem -8rem;
  .menu-icon {
    color: green;
    margin-left: 7rem;
    margin-top: -5rem;
    margin-bottom: -9rem;
  }
  .sidebar_title {
    margin-top: 15px !important;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
    text-transform: capitalize !important;
    color: whitesmoke !important;
    padding: 15px 30px !important;
    font-weight: 900 !important;
    background-color: forestgreen !important;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.75);
  }
  span {
    margin-left: 16px;
  }
  @media (max-width: 500px) {
    .navbar {
      margin-top: -24.3rem;
    }
  }
`;





