import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import EditLocationRoundedIcon from "@mui/icons-material/EditLocationRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import Divider from "@mui/material/Divider";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import GroupIcon from "@mui/icons-material/Group";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

const SidebarData = [
  {
    header: "GENERAL",
    title: "Dashboard",
    icon: <DashboardIcon />,
    divider: <Divider />,
    options: [
      {
        id: 0,
        path: "/",
        con: <ArrowForwardRoundedIcon fontSize={"10px"} />,
        text: "Main Overview",
      },
    ],
  },

  {
    header: "BENEFICIARIES",
    title: "Enrollments",
    icon: <EditLocationRoundedIcon />,
    options: [
      {
        id: 0,
        path: "/formalsector",
        con: <HowToRegRoundedIcon fontSize={"10px"} />,
        text: "Formal Sector",
      },
      {
        id: 1,
        path: "/informalsector",
        con: <ArrowForwardRoundedIcon fontSize={"10px"} />,
        text: "Informal Sector",
      },
      {
        id: 2,
        path: "/equitysector",
        con: <MoreHorizIcon fontSize={"10px"} />,
        text: "Equity Sector",
      },
    ],
  },
  {
    header: "",
    title: "Subscriptions",
    icon: <SubscriptionsIcon />,
    options: [
      {
        id: 0,
        path: "/subscription",
        con: <CheckCircleOutlineIcon fontSize={"10px"} />,
        text: "Enrollee Subscriptions",
      },
    ],
  },
  {
    header: "",
    title: "Encounters",
    icon: <AccountBoxOutlinedIcon />,
    divider: <Divider />,
    options: [
      {
        id: 0,
        path: "/encounter",
        con: <GroupIcon fontSize={"10px"} />,
        text: "Facility Encounters",
      },
    ],
  },

  {
    header: "ACCREDITATION",
    title: "Facilities",
    icon: <HomeWorkOutlinedIcon />,
    divider: <Divider />,
    options: [
      {
        id: 0,
        path: "/facility",
        con: <ArrowForwardRoundedIcon fontSize={"10px"} />,
        text: "Total Facility",
      },
    ],
  },
  {
    header: "ACCOUNTS",
    title: "Transactions",
    icon: <AccountBalanceWalletIcon />,
    divider: <Divider />,
    options: [
      {
        id: 0,
        path: "/transaction",
        con: <CreditScoreIcon fontSize={"10px"} />,
        text: "Transactions History",
      },
    ],
  },

  {
    header: "SETTINGS",
    title: "User Settings",
    icon: <SettingsOutlinedIcon />,
    options: [
      {
        id: 0,
        path: "/settings",
        con: <AccountCircleTwoToneIcon fontSize={"10px"} />,
        text: "Configuration",
      },
    ],
  },
];
export default SidebarData;
