import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Axios from "axios";
import { myTitle } from "../../redux/TitleSlice";
import { useSelector } from "react-redux";

function Facility() {
	const baseURL = process.env.REACT_APP_BASE_URL;
	const title = useSelector(myTitle);
	const [open, setOpen] = useState({});
	const [facility, setFacility] = useState([]);

	const getFacility = useCallback(async () => {
		try {
			const fetch = await Axios.post(`${baseURL}/all/facilities`);

			const myResult = fetch.data.data;
			setFacility(myResult);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getFacility();
	}, [getFacility]);

	return (
		<Wrapper>
			{title !== "FACILITIES" ? (
				""
			) : (
				<>
					<div className="mail-title">
						<div>
							<h3 className="font-cant font-semibold tracking-wider text-green-600 text-xl">
								Approved Facilities
							</h3>
						</div>
					</div>

					<Paper sx={{ width: "100%", overflow: "hidden" }}>
						<TableContainer component={Paper}>
							<Table aria-label="collapsible table">
								<TableHead>
									<TableRow>
										<TableCell className="table-head" />
										<TableCell align="left" className="table-head">
											ID
										</TableCell>
										<TableCell align="left" className="table-head">
											Facility Name
										</TableCell>

										<TableCell align="left" className="table-head">
											LGA
										</TableCell>
									</TableRow>
								</TableHead>

								{facility?.length === 0 ? (
									<h3 style={{ color: "green" }}>No Data yet...</h3>
								) : (
									facility?.map((item, idx) => {
										return (
											<TableBody key={`${item}-${idx}`}>
												<TableRow
													sx={{ "& > *": { borderBottom: "unset" } }}
													className="fac-title"
												>
													<TableCell>
														<IconButton
															aria-label="expand row"
															size="small"
															onClick={() =>
																setOpen((current) => ({
																	[idx]: !current[idx],
																}))
															}
														>
															{open[idx] ? (
																<KeyboardArrowUpIcon />
															) : (
																<KeyboardArrowDownIcon />
															)}
														</IconButton>
													</TableCell>
													<TableCell component="th" scope="row">
														{item?.name?.id}
													</TableCell>
													<TableCell align="left">{item?.name?.name}</TableCell>

													<TableCell align="left">{item?.lga}</TableCell>
												</TableRow>
												<TableRow>
													<TableCell
														style={{ paddingBottom: 0, paddingTop: 0 }}
														colSpan={6}
													>
														<Collapse
															in={open[idx]}
															timeout="auto"
															unmountOnExit
														>
															<Box sx={{ margin: 1 }}>
																<Typography
																	variant="h6"
																	gutterBottom
																	component="div"
																>
																	Details
																</Typography>
																<div className="enc-profile">
																	<table>
																		<thead></thead>
																		<tbody>
																			<tr>
																				<td className="head">
																					<b>Facility Code:</b>
																				</td>
																				<td className="val">
																					{item?.name?.id}
																				</td>
																			</tr>
																			<tr>
																				<td className="head">
																					<b> Facility Name:</b>
																				</td>
																				<td className="val">
																					{item?.name?.name}
																				</td>
																			</tr>

																			<tr>
																				<td className="head">
																					<b>LGA:</b>
																				</td>
																				<td className="val">{item?.lga}</td>
																			</tr>
																			<tr>
																				<td className="head">
																					<b>Ward:</b>
																				</td>
																				<td className="val">{item?.ward}</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															</Box>
														</Collapse>
													</TableCell>
												</TableRow>
											</TableBody>
										);
									})
								)}
							</Table>
						</TableContainer>
					</Paper>
				</>
			)}
		</Wrapper>
	);
}

export default Facility;

const Wrapper = styled.div`
	width: 650px;
	padding: 20px;
	margin: -0.5rem 0 0.2rem 0;
	flex: 1;
	height: 100vh;
	font-family: "Poppins";
	background-color: white;
	overflow: auto;
	.mail-title > h3 {
		margin-bottom: -0.2rem;
	}
	.mail-title {
		margin-bottom: 1rem;
		display: flex;
		padding-bottom: 1rem;
		align-items: center;
		justify-content: space-between;
	}
	.table-head {
		font-weight: 700;
		background-color: #dcdcdc;
	}
	.enc-profile {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.enc-profile img {
		width: 10rem;
		height: 10rem;
		object-fit: cover;
		border-radius: 50%;
		display: block;
		margin: 0 2rem 0 1rem;
	}
	.enc-profile > table {
		margin: 1rem auto;
		font-size: 12px;
	}
	.head {
		padding-bottom: 1rem;
	}
	.val {
		padding-left: 2rem;
		padding-bottom: 1rem;
		color: green;
		font-weight: 600;
	}
	@media (max-width: 500px) {
		width: 500px;
		margin-left: 8rem;
		overflow-x: scroll;
		margin-top: -8rem;
		flex: 1 1 auto;
		border-radius: 15px;
		white-space: nowrap;

		.mail-title {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
			justify-items: center;
			margin-bottom: 1rem;
		}
		.enc-wrapper {
			display: block;
		}
		.enc-table {
			padding-bottom: 2rem;
			border-right: none;
			border-bottom: 2px solid grey;
		}
		.enc-profile {
			display: flex;
			flex-direction: column;
		}
		.enc-profile ul {
			line-height: 2rem;
		}
	}
`;
