import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
//import Axios from "axios";

// https://pshs3.herokuapp.com/encounters/data
function EncounterChart() {
	const state = {
		series: [
			{
				name: "Encounter",
				type: "area",
				data: [
					[1327359600000, 30],
					[1327446000000, 31],
					[1327532400000, 31],
					[1327618800000, 31],
					[1327878000000, 31],
					[1327964400000, 30],
					[1328050800000, 31],
					[1328137200000, 31],
					[1328223600000, 30],
					[1328482800000, 31],
					[1328569200000, 32],
					[1328655600000, 36],
					[1328742000000, 32],
					[1328828400000, 33],
					[1329087600000, 32],
					[1329174000000, 32],
					[1329260400000, 31],
					[1329346800000, 32],
					[1329433200000, 34],
					[1329778800000, 32],
					[1329865200000, 32],
					[1329951600000, 30],
					[1330038000000, 33],
					[1330297200000, 34],

					[1337810400000, 31],
					[1337896800000, 31],
					[1338242400000, 32],
					[1338328800000, 31],
					[1338415200000, 31],
					[1338501600000, 29],
					[1338760800000, 30],
					[1338847200000, 30],
					[1338933600000, 31],
					[1339020000000, 31],
					[1339106400000, 31],
					[1339365600000, 31],
					[1339452000000, 31],
					[1339538400000, 31],
					[1339624800000, 31],
					[1339711200000, 32],
					[1339970400000, 32],
					[1340056800000, 32],
					[1340143200000, 31],
					[1340229600000, 31],
					[1340316000000, 31],
					[1340575200000, 30],
					[1340661600000, 31],
					[1340748000000, 31],
					[1340834400000, 31],
					[1340920800000, 32],
					[1341180000000, 32],
					[1341266400000, 32],
					[1341439200000, 32],
					[1341525600000, 31],
					[1341784800000, 30],
					[1341871200000, 30],
					[1341957600000, 30],
					[1342044000000, 30],
					[1342130400000, 30],
					[1342389600000, 30],
					[1342476000000, 30],
					[1342562400000, 31],
					[1342648800000, 31],
					[1342735200000, 31],
					[1342994400000, 30],
					[1343080800000, 30],
					[1343167200000, 30],
					[1343253600000, 30],
					[1343340000000, 31],
					[1343599200000, 31],
					[1343685600000, 30],
					[1343772000000, 30],

					[1351638000000, 34],
					[1351724400000, 34],
					[1351810800000, 34],
					[1352070000000, 34],
					[1352156400000, 34],
					[1352242800000, 33],
					[1352329200000, 32],
					[1352415600000, 32],
					[1352674800000, 32],
					[1352761200000, 32],
					[1352847600000, 32],
					[1352934000000, 32],
					[1353020400000, 32],
					[1353279600000, 25],
					[1353366000000, 20],
					[1353452400000, 30],
					[1353625200000, 22],
					[1353884400000, 20],
					[1353970800000, 15],
					[1354057200000, 20],
					[1354143600000, 24],
					[1354230000000, 28],
					[1354489200000, 32],
					[1354575600000, 0],
					[1354662000000, 0],
					[1354748400000, 0],
					[1354834800000, 0],
					[1355094000000, 0],

					[1357254000000, 0],
					[1357513200000, 0],
					[1357599600000, 0],

					[1360882800000, 0],
					[1361228400000, 0],
					[1361314800000, 0],
					[1361401200000, 0],
					[1361487600000, 0],
					[1361746800000, 0],
					[1361833200000, 0],
					[1361919600000, 0],
				],
			},
		],
		options: {
			chart: {
				id: "area-datetime",
				type: "area",
				height: 350,
				zoom: {
					autoScaleYaxis: true,
				},
			},
			title: {
				text: "ENCOUNTER OVERVIEW",
				align: "left",
			},
			yaxis: {
				show: false,
				annotations: {
					yaxis: [
						{
							borderColor: "#999",
							label: {
								show: true,
								text: "Date",
								style: {
									color: "#fff",
									background: "#00E396",
								},
							},
						},
					],
					xaxis: [
						{
							x: new Date("14 Nov").getTime(),
							borderColor: "black",
							yAxisIndex: 0,

							labels: {
								show: false,
								text: "Encounter",
								style: {
									color: "#fff",
									background: "#775DD0",
								},
							},
						},
					],
				},
			},
			dataLabels: {
				enabled: false,
			},
			markers: {
				size: 0,
				style: "hollow",
			},
			xaxis: {
				type: "datetime",
				min: new Date("01 Oct 2012").getTime(),
				tickAmount: 6,
				labels: {
					show: false,
				},
			},
			tooltip: {
				x: {
					format: "dd MMM",
				},
			},
			fill: {
				type: "gradient",

				gradient: {
					shadeIntensity: 1,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100],
				},
			},
		},
	};

	return (
		<Wrapper>
			<ReactApexChart
				className="area-charts"
				options={state.options}
				series={state.series}
				type="area"
				height={500}
			/>
		</Wrapper>
	);
}

export default EncounterChart;

const Wrapper = styled.div`
	.area-charts {
		display: table;
		width: 480px;
	}
	@media (max-width: 500px) {
		.area-charts {
			width: 350px;
		}
	}
`;
