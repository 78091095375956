import React, { useState, useCallback } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function Delete({ apiToken }) {
  const [user, setUser] = useState([]);
  const [enrollmentId, setEnrollmentId] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [buttonText, setButtonText] = useState("Verify");

  //base_url
  const baseUrl = process.env.REACT_APP_BASE_URL;

  //verify beneficiary
  const getDetails = useCallback(
    async (e) => {
      e.preventDefault();
      setButtonText("Verifying...");
      try {
        const fetchData = await Axios.post(
          `${baseUrl}/verify/enrid/${enrollmentId}`
        );
        const response = fetchData.data.data;

        if (response) {
          setUser(response);
          setButtonText("Verify");
        } else {
          setUser(null);
          setButtonText("Verify");
          toast.error(
            "Could not verify beneficiary! Please check the enrollment ID and try again",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: false,
            }
          );
        }
      } catch (error) {
        setUser(null);
        setButtonText("Verify");
        console.log(error);
        toast.error("Verification error! Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
        });
      }
    },
    [enrollmentId, baseUrl]
  );

  const handleDelete = (result) => {
    const params = JSON.stringify({
      "principal enrid": user?.enrollment_id,
      "dependent id": result.id,
      mine: true,
    });

    Axios({
      method: "POST",
      url: `${baseUrl}/delete/dep/`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiToken}`,
      },
      data: params,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Dependent has been Deleted successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          });
          const newList = Object.values(user).filter(
            (item) => item?.dependents?.id !== result
          );
          setUser(newList);
          setShowEdit(false);
        } else {
          toast.error(
            "Authorization failed and Access denied. Try again later!",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: false,
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(
          "Error encountered or Authorization failed.Try again later!",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          }
        );
      });
  };
  return (
    <FormWrapper>
      <ToastContainer />

      <Paper className="paper">
        <div>
          <p className="font-pop font-medium text-lg text-white my-2 mx-auto p-3 text-center bg-[#76BA99]">
            Verify Principal
          </p>
          <form onSubmit={getDetails}>
            <div className="flex my-5 items-center justify-around">
              <TextField
                required
                label="Enter Enrollment ID"
                sx={{
                  width: "35ch",
                }}
                value={enrollmentId}
                onChange={(e) => setEnrollmentId(e.target.value)}
              />
              <Button
                type="submit"
                disabled={!enrollmentId ? true : false}
                variant="contained"
                sx={{ ml: 3, width: "20ch", backgroundColor: "#00B4D8" }}
              >
                {buttonText}
              </Button>
            </div>

            <Divider sx={{ mb: 1 }} />
            <p className="font-pop font-medium text-lg text-white my-2 mx-auto p-3 text-center bg-[#76BA99]">
              Principal Details
            </p>

            <TextField
              placeholder="Name"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                width: "40ch",
                mt: 2,
                ml: 10,
              }}
              value={user?.name || ""}
            />
            <TextField
              placeholder="Subcategory item"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                width: "40ch",
                mt: 2,
                ml: 10,
              }}
              value={user?.subcategoryitem?.name || ""}
            />
            <TextField
              placeholder="Facility"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                width: "40ch",
                mt: 2,
                ml: 10,
                mb: 2,
              }}
              value={user?.facility?.name || ""}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  width: "23ch",
                  mb: 1,
                  backgroundColor: "#00B4D8",
                }}
                onClick={() => setShowEdit(!showEdit)}
              >
                Delete Dependent
              </Button>
            </div>
          </form>
          <Divider sx={{ mb: 1 }} />

          {showEdit && (
            <>
              <p className="font-pop font-medium text-lg text-white my-2 mx-auto p-3 text-center bg-[#76BA99]">
                Remove/Delete Dependent
              </p>
              {user?.dependents?.length > 0 ? (
                <table className="table-auto border-collapse my-7 mx-auto border border-slate-400 shadow-lg bg-white">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-slate-300  px-2 py-4">
                        S/N
                      </th>
                      <th className="border border-slate-300  px-8 py-4">
                        Name
                      </th>
                      <th className="border border-slate-300  px-8 py-4">
                        MDA
                      </th>
                      <th className="border border-slate-300  px-2 py-4">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {user?.dependents?.map((dep, indx) => (
                    <tbody key={dep.id}>
                      <tr>
                        <td className="border border-slate-300 px-2 py-4">
                          {indx + 1}
                        </td>
                        <td className="border border-slate-300 px-8 py-4">
                          {dep.name}
                        </td>
                        <td className="border border-slate-300 px-8 py-4">
                          {dep.mda}
                        </td>
                        <td className=" flex items-center border-slate-300 px-2 py-4">
                          {/* <Button
                            onClick={() => handleRemove(dep)}
                            type="submit"
                            title="Remove"
                          >
                            <RemoveCircleOutlineIcon  className="hover:animate-spin" />
                          </Button> */}
                          <Button
                            color="error"
                            onClick={() => handleDelete(dep)}
                            type="submit"
                            title="Delete"
                            disabled={enrollmentId === "" ? true : false}
                          >
                            <HighlightOffIcon className="hover:animate-pulse" />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              ) : (
                <p className="font-cant font-semibold text-lg my-4 mx-auto text-red-500">
                  No Dependent(s)!
                </p>
              )}
            </>
          )}
        </div>
      </Paper>
    </FormWrapper>
  );
}

export default Delete;

const FormWrapper = styled.div`
  margin: 0 3rem 0 17rem;
  .paper {
    padding: 1rem;
    margin-top: -2px;
    margin-left: -7rem;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 40vw;
  }

  .sub-title2 {
    background-color: #006778;
    color: whitesmoke;
    padding: 5px 30px;
    margin-right: 15rem;
  }
  .fac-1 {
    display: flex;
    align-items: center;
  }
`;
