import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import Axios from "axios";
import { useCallback } from "react";

function FormalChart() {
	const [depData, setDepData] = useState([]);
	const [prinData, setPrinData] = useState([]);

	const baseURL = process.env.REACT_APP_BASE_URL;

	const getDep = useCallback(async () => {
		try {
			let fetch = await Axios.get(`${baseURL}/summary/principal/dependents`);

			setDepData(Object.values(fetch.data.dep));
			setPrinData(Object.values(fetch.data.prin));
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getDep();
	}, [getDep]);

	const state = {
		series: [
			{
				data: prinData,
				name: "Principals",
			},
			{
				data: depData,
				name: "Dependents",
			},
		],

		options: {
			chart: {
				type: "bar",
				height: 350,
			},
			title: {
				text: "FORMAL SECTOR OVERVIEW",
				align: "left",
			},
			yaxis: {
				show: false,
				title: {
					text: "TOTAL ENROLLED",
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					startingShape: "flat",
					endingShape: "rounded",
					dataLabels: {
						position: "top",
					},
				},
			},
			dataLabels: {
				enabled: false,
				offsetX: -6,
				style: {
					fontSize: "14px",
					fontFamily: "Ubuntu",
					fontWeight: "bold",
					colors: undefined,
				},
			},
			stroke: {
				show: false,
				width: 4,
				colors: ["#fff"],
			},
			tooltip: {
				shared: true,
				intersect: false,
			},
			noData: {
				text: "Loading...",
				align: "center",
				verticalAlign: "middle",
			},
			xaxis: {
				categories: [
					"ASHM",
					"FSTC",
					"IFX2",
					"MKFM",
					"PLAS",
					"PREH",
					"PROH",
					"RGNX",
					"SLST",
					"UTDC",
					"UTDH",
					"WLHN",
				],
			},
		},
	};

	return (
		<Wrapper>
			<ReactApexChart
				className="area-charts"
				options={state.options}
				series={state.series}
				type="bar"
				height={500}
			/>
		</Wrapper>
	);
}

export default FormalChart;

const Wrapper = styled.div`
	.area-charts {
		display: table;
		width: 520px;
	}
	@media (max-width: 500px) {
		.area-charts {
			width: 350px;
		}
	}
`;
