import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Mailbox from "./Mailbox";
import { Routes, Route } from "react-router-dom";
import SubscriptionDetails from "./SubscriptionDetails";
import EncounterDetails from "./EncounterDetails";
import FacilityDetails from "./FacilityDetails";



function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Designed for Plateau State Contributory Health Care Management Agency by{" "}
      <a className="link" href="https://instantdeposit.africa">
        Instant Deposit Ltd.
      </a>{" "}
      All Rights Reserved.
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const mdTheme = createTheme();

function Page() {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 600,
                  width: 340,
                }}
              >
                <Mailbox />
              </Paper>
            </Grid>

            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 600,
                  ml: 15,
                  width: 650,
                }}
              >
                <Routes>
                  <Route
                    path="/subscriptiondetails"
                    element={<SubscriptionDetails />}
                  />
                  <Route
                    path="/encounterdetails"
                    element={<EncounterDetails />}
                  />
                  <Route
                    path="/facilitydetails"
                    element={<FacilityDetails />}
                  />
                 
                </Routes>
              </Paper>
            </Grid>
          </Grid>
          <Copyright sx={{ pt: 5 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Page;
