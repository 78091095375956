import React, { useState, useCallback, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const url =
  "https://png.pngtree.com/png-vector/20190114/ourmid/pngtree-vector-avatar-icon-png-image_313572.jpg";

const UpdatePhoto = ({ apiToken }) => {
  const [enrollmentId, setEnrollmentId] = useState("");
  const [user, setUser] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Upload");
  const [verifyButton, setVerifyButton] = useState("Verify");

  //base_url
  const baseUrl = process.env.REACT_APP_BASE_URL;

  //verify beneficiary
  const getDetails = useCallback(
    async (e) => {
      e.preventDefault();
      setVerifyButton("Verifying...");
      try {
        const fetchData = await Axios.post(
          `${baseUrl}/verify/enrid/${enrollmentId}`
        );
        const response = fetchData.data.data;
        if (response) {
          setUser(response);
          setVerifyButton("Verify");
        } else {
          setUser(null);
          setVerifyButton("Verify");
          toast.error(
            "Could not verify beneficiary! Please check the enrollment ID and try again",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: false,
            }
          );
        }
      } catch (error) {
        console.log(error);
        setVerifyButton("Verify");
        setUser(null);
        toast.error("Verification error! Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
        });
      }
    },
    [enrollmentId, baseUrl]
  );

  const submitForm = (e) => {
    e.preventDefault();
    setButtonText("Uploading...");
    var formData = new FormData();

    formData.append("enrollment_id", enrollmentId);
    formData.append("file", photo);

    Axios({
      method: "POST",
      url: `${baseUrl}/update/pictures`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${apiToken}`,
      },
      data: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Details updated successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          });
          setShowEdit(false);
          setButtonText("Upload");
        } else {
          setButtonText("Upload");
          toast.error(
            "Authorization failed and Access denied. Try again later!",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: false,
            }
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        setButtonText("Upload");
        toast.error(
          "Error encountered or Authorization failed.Try again later!",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
          }
        );
      });
  };

  useEffect(() => {
    if (!enrollmentId || !user || photo === null) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [enrollmentId, user, photo]);

  return (
    <FormWrapper>
      <ToastContainer />
      <Paper className="paper">
        <div>
          <p className="main-title">Update Photo</p>
          <form onSubmit={getDetails}>
            <div className="verify">
              <TextField
                className="box"
                required
                label="Enter Enrollment ID"
                sx={{
                  width: "50ch",
                  mt: 1,
                }}
                value={enrollmentId}
                onChange={(e) => setEnrollmentId(e.target.value)}
              />
              <Button
                type="submit"
                disabled={!enrollmentId ? true : false}
                variant="contained"
                sx={{ ml: 3, width: "20ch", backgroundColor: "#00B4D8" }}
              >
                {verifyButton}
              </Button>
            </div>

            <Divider sx={{ mb: 1 }} />
            <p className="sub-title">Beneficiary Details</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {user && (
                <img
                  src={user?.passport || url}
                  alt={"img"}
                  width={200}
                  height={200}
                  onError={(e) => (e.target.src = url)}
                  className="image-page"
                />
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <TextField
                className="box"
                placeholder="Name"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: "28ch",
                  mt: 2,
                  mr: 2,
                }}
                value={user?.name || ""}
              />
              <TextField
                className="box"
                placeholder="Gender"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  width: "15ch",
                  mt: 2,
                }}
                value={user?.gender || ""}
              />
            </div>

            <TextField
              className="box"
              placeholder="Phone Number"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                width: "20ch",
                mt: 2,
              }}
              value={user?.phone_number || ""}
            />
            <TextField
              className="box"
              placeholder="Subcategory_item"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                width: "25ch",
                mt: 2,
                ml: 2,
              }}
              value={user?.subcategoryitem?.name || ""}
            />
          </form>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
                width: "23ch",
                mb: 1,
                backgroundColor: "#00B4D8",
              }}
              onClick={() => setShowEdit(!showEdit)}
            >
              {buttonText}
            </Button>
          </div>
        </div>
        <Divider sx={{ mb: 1 }} />
        {showEdit && (
          <>
            <p className="sub-title2">Update Photo</p>
            <form
              onSubmit={submitForm}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <div className="fac1">
                {photo && (
                  <img
                    src={URL.createObjectURL(photo)}
                    alt={"img"}
                    width={200}
                    height={200}
                    className="image-page"
                  />
                )}
              </div>
              <TextField
                className="box"
                required
                name="id_photo"
                type="file"
                accept="image/*"
                label="ID Photo"
                id="contained-button-file"
                multiple
                sx={{
                  width: "30ch",
                  mt: 2,
                  mr: 2,
                }}
                onChange={(e) => setPhoto(e.target.files[0])}
              />

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="submit"
                  type="submit"
                  disabled={isDisabled}
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    width: "25ch",
                    backgroundColor: "#00B4D8",
                  }}
                >
                  Upload
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </FormWrapper>
  );
};

export default UpdatePhoto;
const FormWrapper = styled.div`
  margin: 0 3rem 0 17rem;
  .paper {
    padding: 1rem;
    margin-top: -2px;
    margin-left: -7rem;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 470px;
  }
  .verify {
    display: flex;
    align-items: center;
    flex-grow: auto;
    margin: 1rem;
  }
  .image-page {
    border-radius: 50%;
  }
  .sub-title,
  .main-title,
  .sub-title2 {
    background-color: #00c897;
    color: whitesmoke;
    padding: 10px 0;
    text-align: center;
  }
`;
