import React, { useState, useEffect, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Axios from "axios";
import styled from "styled-components";

const Payment = () => {
  const [payments, setPayments] = useState([]);
  const [open, setOpen] = useState({});
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

//base_url
const baseUrl = process.env.REACT_APP_SAFEPAY_URL;

  const getPayment = useCallback(() => {
    setLoading(true);
    const token = process.env.REACT_APP_TOKEN;
    Axios.get(
      `${baseUrl}/coralpay/pos/querypayment/merchantid/033`,
      {
        headers: {
          "content-Type": "application/json",
          "x-access-token": token,
        },
      }
    )
      .then((res) => {
        setPayments(res.data.data);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [baseUrl]);

  useEffect(() => {
    getPayment();
  }, [getPayment]);

  return (
    <Wrapper>
      <Paper
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "60vw",
          ml: 15,
        }}
      >
        <TableContainer component={Paper}>
          <div className="title-main">
            <span className="pay">Payment History</span>
            <span className="pay">Total Transactions: {count}</span>
          </div>

          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className="table-head" />

                <TableCell align="left" className="table-head">
                  Customer Reference
                </TableCell>
                <TableCell align="left" className="table-head">
                  Customer Phone Number
                </TableCell>

                <TableCell align="left" className="table-head">
                  Product Amount
                </TableCell>
                <TableCell align="left" className="table-head">
                  Payment Reference
                </TableCell>
              </TableRow>
            </TableHead>

            {payments.length >  0 ? (
              payments?.map((record, idx) => {
                return (
                 
                    <TableBody key={idx}>
                      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() =>
                              setOpen((current) => ({
                                ...current,
                                [idx]: !current[idx],
                              }))
                            }
                          >
                            {open[idx] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell component="th" scope="row" key={idx}>
                          {record.customerRef}
                        </TableCell>
                        <TableCell align="left" key={record.customer_phone}>
                          {record.customer_phone}
                        </TableCell>
                        <TableCell align="left" key={record.product_amount}>
                          {record.product_amount}
                        </TableCell>
                        <TableCell align="left" key={record.paymentref}>
                          {" "}
                          {record.paymentref}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse in={open[idx]} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                              >
                                Details of Payment
                              </Typography>
                              <div className="enc-profile">
                                <table>
                                  <thead></thead>
                                  <tbody>
                                    <tr>
                                      <td className="head">
                                        <b>Account Bank:</b>
                                      </td>
                                      <td className="val">
                                        {record?.account_bank || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Bank Account:</b>
                                      </td>
                                      <td className="val">
                                        {record?.bank_account || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Bank ID:</b>
                                      </td>
                                      <td className="val">
                                        {record.bank_id || "No data"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="head">
                                        <b>Biller Code:</b>
                                      </td>
                                      <td className="val">
                                        {record?.biller_code || "No data"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="head">
                                        <b>Business Name:</b>
                                      </td>
                                      <td className="val">
                                        {record?.business_name || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Channel:</b>
                                      </td>
                                      <td className="val">
                                        {record?.channel || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Coral Payment Reference:</b>
                                      </td>
                                      <td className="val">
                                        {record?.coralpaymentReference ||
                                          "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Customer Reference:</b>
                                      </td>
                                      <td className="val">
                                        {record?.customerRef || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Customer ID:</b>
                                      </td>
                                      <td className="val">
                                        {record?.customer_id || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Customer Phone:</b>
                                      </td>
                                      <td className="val">
                                        {record.customer_phone || "No data"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="head">
                                        <b>Display Message:</b>
                                      </td>
                                      <td className="val">
                                        {record?.display_message || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Duration:</b>
                                      </td>
                                      <td className="val">
                                        {record.duration || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Initiating Date:</b>
                                      </td>
                                      <td className="val">
                                        {record?.initiating_date || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Last Update:</b>
                                      </td>
                                      <td className="val">
                                        {record?.last_update || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Merchant Email:</b>
                                      </td>
                                      <td className="val">
                                        {record?.merchant_email || "No data"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="head">
                                        <b>Merchant ID:</b>
                                      </td>
                                      <td className="val">
                                        {record?.merchant_id || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Merchant Phone:</b>
                                      </td>
                                      <td className="val">
                                        {record?.merchant_phone || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Message:</b>
                                      </td>
                                      <td className="val">
                                        {record?.message || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Mobile Number:</b>
                                      </td>
                                      <td className="val">
                                        {record?.mobileNumber || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Payment Channel:</b>
                                      </td>
                                      <td className="val">
                                        {record?.payment_channel || "No data"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="head">
                                        <b>Payment Reference:</b>
                                      </td>
                                      <td className="val">
                                        {record?.paymentref || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b> Product Amount:</b>
                                      </td>
                                      <td className="val">
                                        {record?.product_amount || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Trace ID:</b>
                                      </td>
                                      <td className="val">
                                        {record?.traceId || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Transaction Date :</b>
                                      </td>
                                      <td className="val">
                                        {record?.transaction_date || "No data"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="head">
                                        <b>Validity:</b>
                                      </td>
                                      <td className="val">
                                        {record?.validity || "No data"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                
                );
              })
            ) : (
              <h3 style={{ color: "green" }}>No Data</h3>
            )}
          </Table>
          {loading && <h5>Loading...</h5>}
        </TableContainer>
      </Paper>
    </Wrapper>
  );
};

export default Payment;
const Wrapper = styled.div`
  .title-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem;
  }
  .pay {
    font-size: 20px;
    font-weight: 600;
  }
  .mail-title > h3 {
    margin-bottom: -0.2rem;
  }
  .mail-title {
    margin-bottom: 1rem;
    display: flex;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
  }
  .table-head {
    font-weight: 700;
    background-color: #dcdcdc;
  }
  .enc-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .enc-profile img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    margin: 0 2rem 0 1rem;
  }
  .enc-profile > table {
    margin: 1rem auto;
    font-size: 12px;
  }
  .head {
    padding-bottom: 1rem;
  }
  .val {
    padding-left: 2rem;
    padding-bottom: 1rem;
    color: green;
    font-weight: 600;
  }
`;
