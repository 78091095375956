import React, { useState, useEffect, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Axios from "axios";
import styled from "styled-components";

const Withdrawal = () => {
	const [withdrawal, setWithdrawal] = useState([]);
	const [open, setOpen] = useState({});
	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(0);

	//base_url
	const baseUrl = process.env.REACT_APP_SAFEPAY_URL;

	const getPayment = useCallback(() => {
		setLoading(true);
		const token = process.env.REACT_APP_TOKEN;
		Axios.get(`${baseUrl}/coralpay/pos/queryoutflow/merchantid/033`, {
			headers: {
				"content-Type": "application/json",
				"x-access-token": token,
			},
		})
			.then((res) => {
				setWithdrawal(res.data.data);
				setCount(res.data.count);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [baseUrl]);

	useEffect(() => {
		getPayment();
	}, [getPayment]);

	return (
		<Wrapper>
			<Paper
				sx={{
					p: 4,
					display: "flex",
					flexDirection: "column",
					height: "100vh",
					width: "58vw",
					ml: 15,
				}}
			>
				<TableContainer component={Paper}>
					<div className="title-main">
						<span className="pay">Withdrawal History</span>
						<span className="pay">Total Transactions: {count || "0"}</span>
					</div>

					<Table aria-label="collapsible table">
						<TableHead>
							<TableRow className="">
								<TableCell className="table-head" />

								<TableCell align="left" className="table-head">
									Beneficiary Name
								</TableCell>
								<TableCell align="left" className="table-head">
									Beneficiary Phone Number
								</TableCell>

								<TableCell align="left" className="table-head">
									Amount
								</TableCell>
								<TableCell align="left" className="table-head">
									Channel
								</TableCell>
							</TableRow>
						</TableHead>

						{withdrawal?.length > 0 ? (
							withdrawal?.map((record, idx) => {
								return (
									<TableBody key={idx}>
										<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
											<TableCell>
												<IconButton
													aria-label="expand row"
													size="small"
													onClick={() =>
														setOpen((current) => ({
															...current,
															[idx]: !current[idx],
														}))
													}
												>
													{open[idx] ? (
														<KeyboardArrowUpIcon />
													) : (
														<KeyboardArrowDownIcon />
													)}
												</IconButton>
											</TableCell>

											<TableCell component="th" scope="row" key={idx}>
												{record.BeneficiaryName}
											</TableCell>
											<TableCell align="left" key={record.customer_phone}>
												{record.BeneficiaryPhone}
											</TableCell>
											<TableCell align="left" key={record.product_amount}>
												{record.Amount}
											</TableCell>
											<TableCell align="left" key={record.paymentref}>
												{" "}
												{record.Channel}
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell
												style={{ paddingBottom: 0, paddingTop: 0 }}
												colSpan={6}
											>
												<Collapse in={open[idx]} timeout="auto" unmountOnExit>
													<Box sx={{ margin: 1 }}>
														<Typography
															variant="h6"
															gutterBottom
															component="div"
														>
															Details of Payment
														</Typography>
														<div className="enc-profile">
															<table>
																<thead></thead>
																<tbody>
																	<tr>
																		<td className="head">
																			<b>Beneficiary Name:</b>
																		</td>
																		<td className="val">
																			{record?.BeneficiaryName || "No data"}
																		</td>
																	</tr>
																	<tr>
																		<td className="head">
																			<b>Beneficiary Phone:</b>
																		</td>
																		<td className="val">
																			{record?.BeneficiaryPhone || "No data"}
																		</td>
																	</tr>
																	<tr>
																		<td className="head">
																			<b>Beneficiary Account:</b>
																		</td>
																		<td className="val">
																			{record?.BeneficiaryAccount || "No data"}
																		</td>
																	</tr>

																	<tr>
																		<td className="head">
																			<b>Beneficiary Bank Code:</b>
																		</td>
																		<td className="val">
																			{record?.BeneficiaryBankCode || "No data"}
																		</td>
																	</tr>

																	<tr>
																		<td className="head">
																			<b>Beneficiary Bank Name:</b>
																		</td>
																		<td className="val">
																			{record?.BeneficiaryBankName || "No data"}
																		</td>
																	</tr>
																	<tr>
																		<td className="head">
																			<b>Channel:</b>
																		</td>
																		<td className="val">
																			{record?.Channel || "No data"}
																		</td>
																	</tr>
																	<tr>
																		<td className="head">
																			<b>Amount:</b>
																		</td>
																		<td className="val">
																			{record?.Amount || "No data"}
																		</td>
																	</tr>
																	<tr>
																		<td className="head">
																			<b>Transaction Reference:</b>
																		</td>
																		<td className="val">
																			{record?.TransactionRef || "No data"}
																		</td>
																	</tr>
																	<tr>
																		<td className="head">
																			<b>Destination Bank:</b>
																		</td>
																		<td className="val">
																			{record?.destination || "No data"}
																		</td>
																	</tr>
																	<tr>
																		<td className="head">
																			<b>Beneficiary NIP Code:</b>
																		</td>
																		<td className="val">
																			{record.BeneficiaryNIPCode || "No data"}
																		</td>
																	</tr>

																	<tr>
																		<td className="head">
																			<b>Transaction Date:</b>
																		</td>
																		<td className="val">
																			{record?.log_date || "No data"}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</Box>
												</Collapse>
											</TableCell>
										</TableRow>
									</TableBody>
								);
							})
						) : (
							<h3 style={{ color: "green" }}>No Data</h3>
						)}
					</Table>
					{loading && <h5>Loading...</h5>}
				</TableContainer>
			</Paper>
		</Wrapper>
	);
};

export default Withdrawal;
const Wrapper = styled.div`
	.title-main {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 2rem;
	}
	.pay {
		font-size: 20px;
		font-weight: 600;
	}
	.mail-title > h3 {
		margin-bottom: -0.2rem;
	}
	.mail-title {
		margin-bottom: 1rem;
		display: flex;
		padding-bottom: 1rem;
		align-items: center;
		justify-content: space-between;
	}
	.table-head {
		font-weight: 700;
		background-color: #dcdcdc;
	}
	.enc-profile {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.enc-profile img {
		width: 10rem;
		height: 10rem;
		object-fit: cover;
		border-radius: 50%;
		display: block;
		margin: 0 2rem 0 1rem;
	}
	.enc-profile > table {
		margin: 1rem auto;
		font-size: 12px;
	}
	.head {
		padding-bottom: 1rem;
	}
	.val {
		padding-left: 2rem;
		padding-bottom: 1rem;
		color: green;
		font-weight: 600;
	}
`;
