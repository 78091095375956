import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Routes, Route } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddSubcategory from "./AddSubcategory";
import SubcategoryItem from "./AddSubcategoryItem";
import ChangeFacility from "./ChangeFacility";
import Download from "./Download";
import Delete from "./Delete";
import DeletePrincipal from "./DeletePrincipal";
import Search from "./Search";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CardData from "./cardDownload/CardData";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import UpdateDetails from "./UpdateDetails";
import UpdatePhoto from "./UpdatePhoto";
import UploadIcon from "@mui/icons-material/Upload";
import PublishIcon from "@mui/icons-material/Publish";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			Designed for Plateau State Contributory Health Care Management Agency by{" "}
			<a className="link" href="https://instantdeposit.africa">
				Instant Deposit Ltd.
			</a>{" "}
			All Rights Reserved.
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const mdTheme = createTheme();
const settings = [
	{
		icon: <PersonAddIcon />,
		text: "Add Subcategory",
		link: "addsubcategory",
	},
	{
		icon: <GroupAddIcon />,
		text: "Add Subcategory items",
		link: "addsubcategoryitem",
	},
	{
		icon: <DownloadIcon />,
		text: "Download Sector Data",
		link: "download",
	},
	{
		icon: <ChangeCircleIcon />,
		text: "Change Facility",
		link: "changefacility",
	},
	{
		icon: <DeleteIcon />,
		text: "Delete Principal",
		link: "deletePrincipal",
	},
	{
		icon: <DeleteIcon />,
		text: "Delete Dependents",
		link: "delete",
	},
	{
		icon: <PersonSearchIcon />,
		text: "Search Beneficiaries",
		link: "search",
	},
	{
		icon: <UploadIcon />,
		text: "Update Photo",
		link: "updatephoto",
	},
	{
		icon: <SimCardDownloadIcon />,
		text: "Download Card Data",
		link: "cardData",
	},
	{
		icon: <PublishIcon />,
		text: "Update Details",
		link: "updatedetails",
	},
];

function Settings({ apiToken }) {
	return (
		<ThemeProvider theme={mdTheme}>
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? theme.palette.grey[100]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: "100vh",
					overflow: "auto",
				}}
			>
				<Toolbar />
				<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4} lg={3}>
							<TopWrapper>
								<Paper className="paper">
									<div className=" flex items-center justify-center ">
										<p className="bg-green-500 text-white px-10 py-4 my-4 rounded-md">
											<span>
												<SettingsIcon fontSize="medium" />
											</span>{" "}
											User Settings
										</p>
									</div>
									<div>
										<h4>Folders</h4>
										<Divider />
									</div>
									{settings.map((item, idx) => {
										return (
											<NavLink exact="true" to={item.link} key={idx}>
												<MenuItem className="sidebarOption">
													{item.icon}
													{item.text}
												</MenuItem>
											</NavLink>
										);
									})}
								</Paper>
							</TopWrapper>
						</Grid>

						<Grid item xs={12} md={8} lg={9}>
							<div>
								<Routes>
									<Route
										path="/addsubcategory"
										element={<AddSubcategory apiToken={apiToken} />}
									/>
									<Route
										path="/addsubcategoryitem"
										element={<SubcategoryItem apiToken={apiToken} />}
									/>
									<Route
										path="/changefacility"
										element={<ChangeFacility apiToken={apiToken} />}
									/>
									<Route
										path="/download"
										element={<Download apiToken={apiToken} />}
									/>
									<Route
										path="/delete"
										element={<Delete apiToken={apiToken} />}
									/>
									<Route
										path="/deletePrincipal"
										element={<DeletePrincipal apiToken={apiToken} />}
									/>
									<Route path="/search" element={<Search />} />
									<Route
										path="/carddata"
										element={<CardData apiToken={apiToken} />}
									/>
									<Route
										path="/updatedetails"
										element={<UpdateDetails apiToken={apiToken} />}
									/>
									<Route
										path="/updatephoto"
										element={<UpdatePhoto apiToken={apiToken} />}
									/>
								</Routes>
							</div>
						</Grid>
					</Grid>
					<Copyright sx={{ pt: 5 }} />
				</Container>
			</Box>
		</ThemeProvider>
	);
}

export default Settings;
const TopWrapper = styled.div`
  .paper{
      padding: 2px,
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 320px;
      overflow: hidden;
      overflow-y: scroll;
  
  }
  .btn{
    background-color: green;
    color: white;
    padding: 15px 40px;
    margin: 15px 50px;
  }
  h4{
    margin: 20px 25px;
  }
  .sidebarOption {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: gap;
    height: 40px;
    padding: 30px 5px;
    color: #818589;
    font-weight: 800;
    cursor: pointer;
    font-family: "Poppins";
    text-decoration: none;
  }
  .sidebarOption:hover {
    border-radius: 0 120px 120px 0;
    font-weight: 800 !important;
    border-left: 3px solid forestgreen;
    color: black;
  }
  
  
  
`;
