import React from "react";
import styled from "styled-components";
import ReactApexCharts from "react-apexcharts";

function Overview() {
  const config = {
    series: [
      {
        name: "Encounter",
        type: "column",
        data: [10, 20, 30, 30, 40, 30, 50, 60, 40, 20, 80, 20],
      },
      {
        name: "Enrollment",
        type: "area",
        data: [10, 20, 20, 50, 90, 30, 20, 10, 40, 70, 40, 20],
      },
      {
        name: "Subscription",
        type: "line",
        data: [10, 40, 30, 60, 40, 90, 50, 60, 40, 70, 30, 20],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "LineColumnArea",
        stacked: false,
        toolbar: {
          show: true,
        },
      },

      dataLabels: {
        enabled: false,
      },

      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      title: {
        text: "Overview",
        align: "left",
      },
      plotOptions: {
        bar: {
          columnWidth: "10%",
        },
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      xaxis: {
        categories: [
          " Dec  '20",
          " Jan  '21",
          "  Feb '21",
          " Mar '21",
          " Apr '21",
          " May  '21",
          " Jun  '21",
          "  Jul '21",
          "  Aug  '21",
          "  Sep  '21",
          "  Oct  '21",
          "  Nov  '21",
        ],
        title: {
          text: "",
        },
      },

      yaxis: {
        show: false,
        title: {
          text: "",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    },
  };
  return (
    <Wrapper>
      {/* {isLoaded ? (
        <DefaultChart />
      ) : ( */}
      <div className="three_charts">
        <ReactApexCharts
          options={config.options}
          series={config.series}
          height={400}
        />
      </div>
      {/* )} */}
    </Wrapper>
  );
}

export default Overview;

const Wrapper = styled.div`
  display: table;
  padding: 10px;
  flex-grow: 1;
  width: 600px;
`;
