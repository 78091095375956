import Axios from "axios";
import React, { useState, useCallback } from "react";
import SearchNominal from "./SearchNominal";
import style from "styled-components";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SearchEnrollment from "./SearchEnrollments";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Search() {
  const [search, setSearch] = useState([]);
  const [searchEnroll, setSearchEnroll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  //base_url
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleClick = () => {
    setChecked(!checked);
  };

  //debounce to regulate API call
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 3000);
    };
  };

  //API call for nominal roll
  const handleChange = useCallback(
    (event) => {
      setLoading(true);
      const { value } = event.target;

      const params = JSON.stringify({
        name: value,
      });

      Axios({
        method: "POST",
        url: `${baseUrl}/search/enrollments/nominal`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      })
        .then((response) => {
          if (value && value?.length > 0) {
            setSearch(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            return [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [baseUrl]
  );

  //optimised call for nominal roll
  const optimisedVersion = debounce(handleChange);

  const handleChange2 = useCallback(
    (event) => {
      setLoading(true);
      const { value } = event.target;

      const params = JSON.stringify({
        phone_number: value,
        name: value,
      });

      Axios({
        method: "POST",
        url: `${baseUrl}/search/enrollments/enrollments`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      })
        .then((response) => {
          if (value && value?.length > 0) {
            setSearchEnroll(response.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            return [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [baseUrl]
  );

  //optimised call for enrollments
  const optimisedVersion2 = debounce(handleChange2);

  return (
    <Wrapper>
      <Paper component="form" className="paper" elevation={4}>
        <FormControlLabel
          control={
            <IOSSwitch sx={{ m: 1 }} checked={checked} onClick={handleClick} />
          }
          label="switch to search Nominal Roll"
        />

        {checked ? (
          <TextField
            className="box"
            label="Search Beneficiaries Nominal roll"
            type="text"
            autoComplete="off"
            variant="outlined"
            placeholder="search by name"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: "40ch" }}
            onChange={optimisedVersion}
          />
        ) : (
          <TextField
            className="box"
            label="Search Beneficiaries Enrollment"
            type="text"
            autoComplete="off"
            variant="outlined"
            placeholder="search by name or phone number"
            sx={{ width: "40ch" }}
            onChange={optimisedVersion2}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Paper>
      <span>{loading ? <i>Searching...</i> : ""}</span>
      <div className="search-item">
        {checked
          ? search?.length > 0 && <SearchNominal search={search} />
          : searchEnroll?.length > 0 && (
              <SearchEnrollment searchEnroll={searchEnroll} />
            )}
      </div>
    </Wrapper>
  );
}

export default Search;

const Wrapper = style.div`
  margin-left: 7rem;
  padding: 3rem;
  background-color: #dfdfde;
  .paper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
  
  @media (max-width: 500px) {
    margin: 0;
    .paper {
      margin-left: -2rem;
      width: 350px;
    }
    .search-item {
      width: 450px;
      margin-left: -5rem;
    }
  }
`;
