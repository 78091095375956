import React, { useState, useEffect, useCallback } from "react";

import Axios from "axios";
import CountUp from "react-countup";
import { FaHospitalAlt } from "react-icons/fa";
import { Line } from "rc-progress";
import { Card } from "antd";

function FacilityCard() {
	const [newFacilities, setNewFacilities] = useState("0");
	const [facilityIncrease, setFacilityIncrease] = useState("0");

	const baseURL = process.env.REACT_APP_BASE_URL;

	const getNewFacilities = useCallback(async () => {
		try {
			const fetchFacility = await Axios.get(`${baseURL}/new_facilities`);
			setNewFacilities(fetchFacility.data.new_facilities);
			setFacilityIncrease(fetchFacility.data.percent_increase);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getNewFacilities();
	}, [getNewFacilities]);

	return (
		<Card
			title="FACILITIES"
			className="bg-[#c4b454] text-center"
			bordered={true}
			style={{
				width: 350,
			}}
		>
			<div className="flex items-center space-x-10 my-5">
				<div className="bg-white p-5 opacity-80 rounded-md shadow-lg">
					<FaHospitalAlt className="text-5xl group-hover:animate-pulse" />
				</div>

				<CountUp
					style={{
						fontSize: "40px",
						fontWeight: "700",
						color: "black",
						letterSpacing: "2px",
					}}
					start={1}
					end={newFacilities}
					duration={15}
					separator={","}
				/>
			</div>
			<div className="px-10">
				<Line
					percent={parseInt(facilityIncrease)}
					strokeWidth="3"
					strokeColor="red"
					strokeLinecap="round"
					style={{ width: "200px" }}
				/>

				<p className="font-semibold">
					{" "}
					{facilityIncrease === undefined ? "0" : parseInt(facilityIncrease)}%
					Increase in 180 Days
				</p>
			</div>
		</Card>
	);
}

export default FacilityCard;
