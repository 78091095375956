import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactApexCharts from "react-apexcharts";
import Axios from "axios";
import DefaultChart from "./OverviewDef";
import { useCallback } from "react";

function Overview() {
	const [EncounterData, setEncounterData] = useState([]);
	const [formalData, setFormalData] = useState([]);
	const [subData, setSubData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(true);
	const [label, setLabel] = useState([]);

	const baseURL = process.env.REACT_APP_BASE_URL;

	const getData = useCallback(async () => {
		try {
			let fetch = await Axios.get(`${baseURL}/overview`);

			setEncounterData(fetch.data.encounter.map((e) => e.y));
			setFormalData(fetch.data.enrollment.map((c) => c.y));
			setLabel(fetch.data.enrollment.map((c) => c.x));
			setSubData(fetch.data.subscription.map((sub) => sub.y));
			setIsLoaded(false);
		} catch (err) {
			console.log(err);
		}
	}, [baseURL]);

	useEffect(() => {
		getData();
	}, [getData]);

	const config = {
		series: [
			{
				name: "Encounter",
				type: "column",
				data: EncounterData,
			},
			{
				name: "Enrollment",
				type: "area",
				data: formalData,
			},
			{
				name: "Subscription",
				type: "line",
				data: subData,
			},
		],
		options: {
			chart: {
				height: 350,
				type: "LineColumnArea",
				stacked: false,
				toolbar: {
					show: true,
				},
			},

			dataLabels: {
				enabled: false,
			},

			stroke: {
				width: [0, 2, 5],
				curve: "smooth",
			},
			title: {
				text: "Overview",
				align: "left",
			},
			plotOptions: {
				bar: {
					columnWidth: "10%",
				},
			},
			fill: {
				opacity: [0.85, 0.25, 1],
				gradient: {
					inverseColors: false,
					shade: "light",
					type: "vertical",
					opacityFrom: 0.85,
					opacityTo: 0.55,
					stops: [0, 100, 100, 100],
				},
			},
			xaxis: {
				type: "date",
				categories: label,
				tickAmount: 12,
				title: {
					text: "",
				},
			},

			yaxis: {
				show: false,
				title: {
					text: "",
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				x: {
					format: "MMM yy",
				},
			},
		},
	};
	return (
		<Wrapper>
			{isLoaded ? (
				<DefaultChart />
			) : (
				<div className="three_charts">
					<ReactApexCharts
						options={config.options}
						series={config.series}
						height={400}
					/>
				</div>
			)}
		</Wrapper>
	);
}

export default Overview;

const Wrapper = styled.div`
	display: table;
	padding: 10px;
	flex-grow: 1;
	@media (max-width: 500px) {
		padding: 2px;
		.three_charts {
			width: 350px;
		}
	}
`;
